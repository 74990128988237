import React from "react";
import { useTranslation } from "react-i18next";

import { useCheckPermissionsForReqTask } from "api";
import { useAppSelector } from "app";
import { Icon } from "ui-kit";

import {
  Stack,
  Grid,
  Card,
  Typography,
  CardContent,
  useTheme,
} from "@mui/material";
import { getLocaleDateFormat } from "@sbm/fe-utils";
import { ITaskById } from "@types";

import { TaskDetailTextSection } from "../TaskDetailTextSection";
import { TaskDetailsHeader } from "./TaskDetailsHeader";

interface FeedbackRequestTaskDetailProps {
  data: ITaskById;
  isInDrawer?: boolean;
}

export const FeedbackRequestTaskDetail: React.FC<
  FeedbackRequestTaskDetailProps
> = ({ data, isInDrawer }) => {
  const { t } = useTranslation("tasks");
  const { userProfile } = useAppSelector((state) => state.auth);
  const { palette } = useTheme();

  const {
    authorOfTheTask,
    executorOfTheTask,
    registration,
    typeOfTheTask,
    executionRequirements,
  } = data;

  const isExecutor = userProfile?.personalData?.employees?.some(
    (i) => i.id === executorOfTheTask?.employee?.id
  );

  const isAuthor =
    userProfile?.personalData?.employees?.some(
      (i) => i.id === authorOfTheTask?.employee.id
    ) || false;

  const assignmentDate = getLocaleDateFormat(
    data.registration.taskDate,
    "hh:mm"
  );
  const isHighPriority = registration.priority;
  const hasPersonalControl = executionRequirements.personalControl;

  const { data: checkPermission } = useCheckPermissionsForReqTask();

  return (
    <Stack mt={6} gap={6}>
      <TaskDetailsHeader
        type={typeOfTheTask}
        isAuthor={isAuthor}
        status={data.registration.status}
        authorStatus={data.statusForAuthor}
        data={data}
        isExecutor={Boolean(isExecutor)}
        registerSBMRequest={Boolean(checkPermission?.[0]?.hasPermission)}
      />
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Typography variant="h9_semiBold" color="text.disabled">
                    {t("author.of.task").toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item lg={isInDrawer ? 12 : 4} xs={12}>
                  <TaskDetailTextSection
                    label={t("feedback.organization")}
                    content={
                      authorOfTheTask.organization.briefTextOrganizationName
                    }
                    withTooltip
                  />
                </Grid>
                <Grid item lg={isInDrawer ? 12 : 4} xs={12}>
                  <TaskDetailTextSection
                    label={t("feedback.position")}
                    content={authorOfTheTask.position.namePosition}
                    withTooltip={Boolean(
                      authorOfTheTask.structuralUnit?.nameOfStructuralUnit
                    )}
                    tooltipText={
                      authorOfTheTask.structuralUnit?.nameOfStructuralUnit
                    }
                  />
                </Grid>
                <Grid item lg={isInDrawer ? 12 : 4} xs={12}>
                  <TaskDetailTextSection
                    label={t("feedback.fullName")}
                    content={authorOfTheTask.employee.fullName}
                    withTooltip
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container spacing={6} columns={20}>
                <Grid item xs={20}>
                  <Typography variant="h9_semiBold" color="text.disabled">
                    {t("registration").toUpperCase()}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={20}
                  lg={isInDrawer ? 20 : 6}
                  xl={isInDrawer ? 20 : 4}
                >
                  <TaskDetailTextSection
                    label={t("feedback.request_number")}
                    content={registration.internalRegistrationNumber}
                    withTooltip
                  />
                </Grid>
                <Grid
                  item
                  xs={20}
                  lg={isInDrawer ? 20 : 6}
                  xl={isInDrawer ? 20 : 4}
                >
                  <TaskDetailTextSection
                    label={t("feedback.date_of_appointment")}
                    content={assignmentDate}
                    withTooltip
                  />
                </Grid>
                <Grid
                  item
                  xs={20}
                  lg={isInDrawer ? 20 : 6}
                  xl={isInDrawer ? 20 : 4}
                >
                  <TaskDetailTextSection
                    label={t("feedback.int_number")}
                    content={registration.taskNumber}
                    withTooltip
                  />
                </Grid>
                <Grid
                  item
                  xs={20}
                  lg={isInDrawer ? 20 : 6}
                  xl={isInDrawer ? 20 : 4}
                >
                  <TaskDetailTextSection
                    label={t("status")}
                    content={t(`statuses.${registration.status}`)}
                    withTooltip
                  />
                </Grid>
                <Grid
                  item
                  xs={20}
                  lg={isInDrawer ? 20 : 6}
                  xl={isInDrawer ? 20 : 4}
                >
                  <TaskDetailTextSection
                    label={t("priority")}
                    content={
                      <Stack flexDirection="row" alignItems="center" gap={1.5}>
                        <Icon
                          name={isHighPriority ? "Triangle" : "Equal"}
                          size={20}
                          color={
                            isHighPriority
                              ? palette.error.main
                              : palette.success.main
                          }
                        />

                        <Typography variant="body2" fontWeight={600}>
                          {isHighPriority
                            ? t("priority.high")
                            : t("priority.low")}
                        </Typography>
                      </Stack>
                    }
                    withTooltip
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Typography variant="h9_semiBold" color="text.disabled">
                    {t("feedback.request")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack>
                    <Typography variant="body4" color="text.secondary">
                      {t("feedback.request_text")}
                    </Typography>
                    <div>
                      <Typography variant="body2" fontWeight={600}>
                        {executionRequirements.textOfTheTask}
                      </Typography>
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <TaskDetailTextSection
                    label={t("personal_control")}
                    content={
                      <Stack flexDirection="row" alignItems="center" gap={1.5}>
                        <Icon
                          name={hasPersonalControl ? "Check" : "Minus"}
                          size={20}
                          color={
                            hasPersonalControl
                              ? palette.success.main
                              : palette.primary.main
                          }
                        />
                      </Stack>
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};
