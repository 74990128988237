import React from "react";
import { useTranslation } from "react-i18next";

import { openDrawer, useAppDispatch } from "app";
import { Icon } from "ui-kit";

import {
  ListItemIcon,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import {
  CorrespondenceActionsTypeEnum,
  DrawerVariantsEnum,
  TIconNames,
} from "@types";

const ITEM_HEIGHT = 48;

interface ServiceNotesActionsMenuProps {
  actionTypes: CorrespondenceActionsTypeEnum[];
}

export const ServiceNotesActionsMenu: React.FC<
  ServiceNotesActionsMenuProps
> = ({ actionTypes }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("correspondence");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget as unknown as HTMLElement);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    dispatch(openDrawer(DrawerVariantsEnum.editCorrespondence));
    handleClose();
  };

  const handleGenerateFile = () => {
    console.log("generate file");
  };

  const getItemConfig = (type: CorrespondenceActionsTypeEnum) => {
    switch (type) {
      case CorrespondenceActionsTypeEnum.edit:
        return {
          title: t("edit"),
          iconName: "PencilIcon",
          onClick: handleEdit,
        };
      case CorrespondenceActionsTypeEnum.generate:
        return {
          title: t("generate_file"),
          iconName: "ArrowDownToLine",
          onClick: handleGenerateFile,
        };
    }
  };

  return (
    <Stack display="flex">
      <Icon
        onClick={handlePopoverOpen}
        name="MoreVerticalIcon"
        color={theme.palette.secondary.main}
      />
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: "20ch",
            borderRadius: "16px",
          },
        }}
      >
        {actionTypes.map((type) => {
          const { iconName, title, onClick } = getItemConfig(type);

          return (
            <MenuItem key={type} sx={{ px: 4, py: 3 }} onClick={onClick}>
              <ListItemIcon>
                {iconName && (
                  <Icon
                    name={iconName as TIconNames}
                    color={theme.palette.primary.main}
                    className="cursor-pointer"
                  />
                )}
              </ListItemIcon>
              <Typography variant="body1">{title}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};
