import React from "react";

import { GlobalLoader, Icon, PageLoader } from "ui-kit";

import { useTheme } from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";

import { useAppDispatch, useAppSelector, useMedia } from "../../hooks";
import { useSocket, EventTypeEnum } from "../../services";
import { getContractData, getCurrentUser } from "../../store";
import { Header, Inbox, Sidebar } from "./components";
import { Main, Wrapper, ArrowWrapper } from "./styles";

export interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout = (props: MainLayoutProps) => {
  const { children } = props;

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [{ smDown }] = useMedia();

  const { socket } = useSocket();

  const isFetchingMessages = useIsFetching({
    queryKey: ["get_user_messages"],
    predicate: (query) => {
      return (
        query.state.status === "loading" && query.state.dataUpdatedAt === 0
      );
    },
  });

  const isFetchingApprovals = useIsFetching({
    queryKey: ["get_single_approvals", true],
    predicate: (query) => {
      return (
        query.state.status === "loading" && query.state.dataUpdatedAt === 0
      );
    },
  });

  const isFetching = isFetchingMessages > 0 && isFetchingApprovals > 0;

  const { userProfile } = useAppSelector((state) => state.auth);
  const { contractDirectories } = useAppSelector((state) => state.contracts);

  const [appInitialLoading, setAppInitialLoading] = React.useState(true);
  const [open, setOpen] = React.useState(true);

  const handleToggle = () => {
    setOpen((prevState) => !prevState);
  };

  React.useEffect(() => {
    if (smDown) {
      setOpen(false);
    }
  }, [smDown]);

  React.useEffect(() => {
    if (!isFetching && appInitialLoading) {
      setTimeout(() => {
        setAppInitialLoading(false);
      }, 3000);
    }
  }, [appInitialLoading, isFetching]);

  React.useEffect(() => {
    void dispatch(getCurrentUser());
  }, [dispatch]);

  React.useEffect(() => {
    if (userProfile && !contractDirectories) {
      void dispatch(getContractData());
    }
  }, [dispatch, userProfile, contractDirectories]);

  React.useEffect(() => {
    return () => {
      if (socket?.connected) {
        socket.disconnect();
      }
    };
  }, [socket]);

  React.useEffect(() => {
    if (socket) {
      socket.emit(EventTypeEnum.activeUser);
    }
  }, [socket]);

  React.useEffect(() => {
    if (window.Notification && Notification.permission !== "denied") {
      void Notification.requestPermission();
    }
  }, []);

  if (!userProfile) return <PageLoader />;

  return (
    <Wrapper $overflowHidden={appInitialLoading}>
      {appInitialLoading && <GlobalLoader />}

      <Sidebar open={open} onToggle={handleToggle} />
      <Header open={open} />
      <Main>
        <Inbox />

        {!open && (
          <ArrowWrapper>
            <Icon
              name="ChevronRight"
              color={theme.palette.secondary.main}
              size={16}
              onClick={handleToggle}
            />
          </ArrowWrapper>
        )}

        {children}
      </Main>
    </Wrapper>
  );
};
