import React from "react";
import { UseFormSetValue, Control, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "app";

import { Autocomplete, Chip, TextField } from "@mui/material";
import { ServiceNoteEntity } from "@types";

interface Props {
  setValue: UseFormSetValue<ServiceNoteEntity>;
  control: Control<ServiceNoteEntity>;
}

export const RegistrationAreaConstructionComplexes: React.FC<Props> = ({
  setValue,
  control,
}) => {
  const disabledConstructionComplexes: number[] = [];
  const { t } = useTranslation("correspondence");

  const { contractDirectories } = useAppSelector((state) => state.contracts);

  const { constructionComplexes } = useWatch({ control });

  const getDefaultValues = () =>
    constructionComplexesIDS.filter((complex) =>
      constructionComplexes?.includes(complex.value)
    ) || [];

  const constructionComplexesIDS = React.useMemo(() => {
    if (!contractDirectories) return [];

    return contractDirectories.constructionComplex.map((i) => ({
      value: i.id,
      title: i?.briefTextConstructionComplexName,
    }));
  }, [contractDirectories]);

  return (
    <div>
      <Autocomplete
        multiple
        options={constructionComplexesIDS}
        value={constructionComplexesIDS.filter((value) =>
          constructionComplexes?.includes(value.value)
        )}
        onChange={(_, newValue) => {
          const idsToSet = [
            ...disabledConstructionComplexes,
            ...newValue
              .map((i) => i.value)
              .filter((v) => !disabledConstructionComplexes.includes(v)),
          ];
          setValue("constructionComplexes", idsToSet, {
            shouldDirty: true,
          });
        }}
        getOptionLabel={(option) => option.title}
        defaultValue={getDefaultValues()}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            color="secondary"
            size="medium"
            label={t(
              "createLetterDrawer.registration_area.construction_complexes"
            )}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            return (
              <div key={index}>
                <Chip
                  label={option.title}
                  {...getTagProps({ index })}
                  disabled={disabledConstructionComplexes.includes(
                    option.value
                  )}
                />
              </div>
            );
          })
        }
      />
    </div>
  );
};
