// @ts-nocheck
import React, { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { axiosService } from "app";

import { SelectChangeEvent, Stack } from "@mui/material";
import { getPersonalDataName } from "@sbm/fe-utils";
import { FormAutocomplete, FormSelect } from "@sbm/ui-components";
import { IOrganization, IPersonalData, StructuralUnitTypesEnum } from "@types";

import { DetailsStepProps } from "../DetailsStep";

export const ServiceNoteActorForm: React.FC<
  {
    position: number;
    isEditMode?: boolean;
  } & Pick<DetailsStepProps, "control" | "setValue">
> = ({ setValue, control, position, isEditMode }) => {
  const { t } = useTranslation("correspondence");

  const { recipients, senderOrganizationId } = useWatch({ control });

  const isExternalSubstitution = Boolean(
    recipients?.[position]?.recipientExternalSubstitutionId
  );

  const recipientEmployeeId =
    recipients?.[position]?.recipientExternalSubstitutionId ||
    recipients?.[position]?.recipientEmployeeId;

  const [showStructuralUnit, setShowStructuralUnit] = React.useState(true);

  const [selectedOrganization, setSelectedOrganization] =
    React.useState<IOrganization | null>(null);
  const [inputEmployeeValue, setInputEmployeeValue] = React.useState("");

  const [employeeOptions, setEmployeeOptions] = React.useState<
    {
      title: string;
      id?: number;
      position: "string";
      structuralUnits: {
        structuralUnitId: number;
        positionName: string;
        externalSubstitutionId: number;
        structuralUnitType: StructuralUnitTypesEnum;
        nameOfStructuralUnit: string;
      }[];
    }[]
  >([]);

  const selectedEmployee = employeeOptions.find((i) => {
    if (isExternalSubstitution) {
      const externalSub = i.structuralUnits.find(
        (unit) => unit.externalSubstitutionId === recipientEmployeeId
      );

      return Boolean(externalSub);
    }

    return i.id === recipientEmployeeId;
  });

  const recipientPositionOptions = useMemo(() => {
    if (employeeOptions?.length > 0) {
      if (!selectedEmployee) return [];

      const positions = selectedEmployee.structuralUnits.map((i) => ({
        option: i.positionName,
        value: i.positionName,
      }));

      return positions || [];
    }

    return [];
  }, [employeeOptions, selectedEmployee]);

  const recipientStructuralUnitsOptions = useMemo(() => {
    if (employeeOptions?.length > 0) {
      if (!selectedEmployee) return [];

      const positions = selectedEmployee.structuralUnits.map((i) => ({
        option: i.nameOfStructuralUnit,
        value: i.structuralUnitId,
      }));

      return positions || [];
    }

    return [];
  }, [employeeOptions, selectedEmployee]);

  // Change listeners
  const handleEmployeeInputChange = (val: string, reason: string) => {
    setInputEmployeeValue(val);

    if (reason === "input") return;
    if (reason === "clear") {
      setShowStructuralUnit(true);
      setValue(`recipients[${position}].recipientEmployeeId`, undefined, {
        shouldDirty: true,
      });
      setValue(`recipients[${position}].recipientEmployeePosition`, undefined);
      setValue(`recipients[${position}].recipientStructuralUnitId`, undefined);
      setValue(
        `recipients[${position}].recipientExternalSubstitutionId`,
        undefined
      );
      return;
    }
    if (reason === "reset") {
      const option = employeeOptions.find((i) => i.title === val);
      if (!option) return;

      setShowStructuralUnit(true);

      const selectedStructuralUnit = option.structuralUnits[0];

      if (!selectedStructuralUnit) return;
      setValue(`recipients[${position}].recipientStructuralUnitId`, undefined);

      const isExternalSubstitution = Boolean(
        selectedStructuralUnit?.externalSubstitutionId
      );

      if (isExternalSubstitution) {
        setValue(
          `recipients[${position}].recipientExternalSubstitutionId`,
          selectedStructuralUnit.externalSubstitutionId,
          { shouldDirty: true }
        );
      } else {
        setValue(`recipients[${position}].recipientEmployeeId`, option.id, {
          shouldDirty: true,
        });
      }

      if (option.structuralUnits.length === 1) {
        const structuralUnit = option.structuralUnits[0];

        const isHeadOfStructuralUnit =
          structuralUnit.structuralUnitType ===
            StructuralUnitTypesEnum.mainGoverningBody ||
          structuralUnit.structuralUnitType ===
            StructuralUnitTypesEnum.goveringBody;

        if (isHeadOfStructuralUnit) {
          setShowStructuralUnit(false);
          setValue(
            `recipients[${position}].recipientStructuralUnitId`,
            undefined,
            { shouldDirty: true }
          );
        } else {
          setValue(
            `recipients[${position}].recipientStructuralUnitId`,
            structuralUnit.structuralUnitId,
            { shouldDirty: true }
          );
        }
      }

      if (option.structuralUnits.length === 1) {
        setValue(
          `recipients[${position}].recipientEmployeePosition`,
          selectedStructuralUnit.positionName
        );
      }
    }
  };

  // Default values
  const getEmployeeDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !employeeOptions.length) return "";

      const option = employeeOptions.find((i) => {
        if (isExternalSubstitution) {
          const externalSub = i.structuralUnits.find(
            (unit) => unit.externalSubstitutionId === recipientEmployeeId
          );

          return Boolean(externalSub);
        }

        return i.id === id;
      });

      if (!option) return "";

      return option.title;
    },
    [employeeOptions, isExternalSubstitution, recipientEmployeeId]
  );

  const fetchEmployees = React.useCallback(
    async (search: string) => {
      if (!senderOrganizationId) return [];

      const { data } = await axiosService({
        endpoint: `employees/organizations-structural-units/${senderOrganizationId}`,
        body: {
          search,
          "filter.headOfStructuralUnit": true,
        },
      });

      const result = data
        ? (
            data.items as {
              personalData: IPersonalData;
              employeeId: number;
              structuralUnits: {
                structuralUnitId: number;
                positionName: string;
                externalSubstitutionId: number;
                structuralUnitType: StructuralUnitTypesEnum;
                nameOfStructuralUnit: string;
              }[];
            }[]
          ).map((i) => ({
            title: getPersonalDataName(i.personalData, true),
            id: i.employeeId,
            structuralUnits: i.structuralUnits,
          }))
        : [];

      if (isEditMode && recipientEmployeeId) {
        const isInDefaultGetList = !result.find(
          (r) => r.id !== recipientEmployeeId
        );

        if (!isInDefaultGetList) {
          const { data: employee } = await axiosService({
            endpoint: `employees/${recipientEmployeeId}`,
          });

          if (employee) {
            result.push({
              title: getPersonalDataName(employee.personalData, true),
              id: employee.id,
              structuralUnits:
                [
                  {
                    structuralUnitId: employee.staffUnit?.structuralUnitId,
                    positionName: employee.staffUnit?.namePosition,
                    externalSubstitutionId: employee.hasExternalSubstitutions
                      ? employee.externalSubstitutions?.[0]?.id
                      : undefined,
                    structuralUnitType: employee.staffUnit?.structuralUnitType,
                    nameOfStructuralUnit:
                      employee.staffUnit?.nameOfStructuralUnit,
                  },
                ] || [],
            });
          }
        }
      }

      return result;
    },
    [senderOrganizationId, recipientEmployeeId, isEditMode]
  );

  const handleStructuralUnitChangeListener = (e: SelectChangeEvent<string>) => {
    const newStructuralUnitId = e.target.value as unknown as number;

    if (employeeOptions.length) {
      const employee = employeeOptions.find((i) => {
        if (isExternalSubstitution) {
          const externalSub = i.structuralUnits.find(
            (unit) => unit.externalSubstitutionId === recipientEmployeeId
          );

          return Boolean(externalSub);
        }

        return i.id === recipientEmployeeId;
      });

      if (!employee) return;

      const newPosition = employee.structuralUnits.find(
        (i) => i.structuralUnitId === newStructuralUnitId
      );

      if (!newPosition) return;

      setValue(
        `recipients[${position}].recipientEmployeePosition`,
        newPosition.positionName,
        { shouldDirty: true }
      );

      if (newPosition.externalSubstitutionId) {
        setValue(
          `recipients[${position}].recipientExternalSubstitutionId`,
          newPosition.externalSubstitutionId,
          { shouldDirty: true }
        );
      } else {
        setValue(
          `recipients[${position}].recipientExternalSubstitutionId`,
          newPosition.externalSubstitutionId,
          undefined
        );
        setValue(
          `recipients[${position}].recipientStructuralUnitId`,
          newPosition.structuralUnitId,
          { shouldDirty: true }
        );
      }
    }
  };

  useEffect(() => {
    if (senderOrganizationId) {
      const getSenderOrganization = async () => {
        const { data } = await axiosService({
          endpoint: `organizations/${senderOrganizationId}`,
        });

        return data as IOrganization;
      };

      getSenderOrganization().then((org) => setSelectedOrganization(org));
    }
  }, [senderOrganizationId]);

  return (
    <Stack gap={4} key={position}>
      <FormAutocomplete
        required
        freeSolo={false}
        disabled={!selectedOrganization}
        inputValue={inputEmployeeValue}
        label={t("createLetterDrawer.senders_area.fullName")}
        defaultSelected={getEmployeeDefaultValue(recipientEmployeeId)}
        fetchData={fetchEmployees}
        onFetchDataSuccess={setEmployeeOptions}
        onInputChange={handleEmployeeInputChange}
      />

      {showStructuralUnit && (
        <FormSelect
          label={t("createServiceNote.senders_area.structural_unit")}
          name={`recipients[${position}].recipientStructuralUnitId`}
          control={control}
          values={recipientStructuralUnitsOptions}
          onChangeListener={handleStructuralUnitChangeListener}
        />
      )}

      <FormSelect
        disabled
        label={t("createLetterDrawer.senders_area.position")}
        name={`recipients[${position}].recipientEmployeePosition`}
        control={control}
        values={recipientPositionOptions}
      />
    </Stack>
  );
};
