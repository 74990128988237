import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUploadLinkToCorrespondenceMutation } from "api";
import {
  closeDrawer,
  resetTaskCreationStep,
  setModal,
  TransitionPrompt,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer, Icon } from "ui-kit";

import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { Button, FormRadio, Input } from "@sbm/ui-components";
import {
  CorrespondenceTypeEnum,
  IAttachLink,
  LinkAttachedToCorrespondenceTypeOfAttachmentEnum,
  ModalVariants,
} from "@types";

import { AttachedLink } from "./AttachedLink";
import { IAttachesCorrespondenceLinkForm } from "./constants";
import { createRequestBody } from "./helpers";
import { Wrapper } from "./styles";

interface Props {
  correspondenceId: number | undefined;
  type: CorrespondenceTypeEnum;
}

export const AttachesCorrespondenceLinkForm = ({
  correspondenceId,
  type,
}: Props) => {
  const { t: tAttachments } = useTranslation("attachments");
  const { t } = useTranslation("correspondence");

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const ref = React.useRef<HTMLFormElement | null>(null);

  const { drawer } = useAppSelector((state) => state.global);

  const [showPrompt, setShowPrompt] = useState(false);

  const { reset, register, handleSubmit, control, getValues, setValue } =
    useForm<IAttachesCorrespondenceLinkForm>();

  const { fileAppointment, additionalInformation, links } = useWatch({
    control,
  });

  const handleCloseDrawer = () => {
    reset();
    dispatch(resetTaskCreationStep());
    dispatch(closeDrawer());
  };

  const handleClose = () => {
    handleCloseDrawer();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCancel = () => {
    if (getValues("additionalInformation") || getValues("fileAppointment")) {
      setShowPrompt(true);
      return;
    }
    handleClose();
  };

  const handleConfirmTransition = () => {
    reset();
    handleClose();
  };

  const { mutate: uploadLink, isLoading } =
    useUploadLinkToCorrespondenceMutation(
      handleConfirmTransition,
      handleConfirmTransition
    );

  const assignmentOptions = [
    {
      option: t(
        type === CorrespondenceTypeEnum.incomingDocument
          ? "attachmentFilesAndLinks.response_to_incoming_document"
          : type === CorrespondenceTypeEnum.serviceNote
          ? "attachmentFilesAndLinks.response_to_service_note"
          : "attachmentFilesAndLinks.response_to_outgoing_document"
      ),
      value:
        type === CorrespondenceTypeEnum.incomingDocument
          ? LinkAttachedToCorrespondenceTypeOfAttachmentEnum.responseToIncomingDocument
          : type === CorrespondenceTypeEnum.serviceNote
          ? LinkAttachedToCorrespondenceTypeOfAttachmentEnum.responseToServiceNote
          : LinkAttachedToCorrespondenceTypeOfAttachmentEnum.responseToOutgoingDocument,
    },
    {
      option: t("attachmentFilesAndLinks.initiating_document"),
      value:
        LinkAttachedToCorrespondenceTypeOfAttachmentEnum.initiatingDocument,
    },
    {
      option: t("attachmentFilesAndLinks.auxiliary_document"),
      value: LinkAttachedToCorrespondenceTypeOfAttachmentEnum.auxiliaryDocument,
    },
  ];

  const addAttachLink = (link: IAttachLink) => {
    const newList = getValues("links") || [];
    newList.push(link);
    setValue("links", newList);
  };

  const onSubmit = handleSubmit(async (body) => {
    if (!ref.current || !correspondenceId) return;
    const requestBody = createRequestBody(body);

    uploadLink({ requestBody, correspondenceId });
  });

  const handleAttachLink = () => {
    if (getValues("links")?.length === 10 || !fileAppointment) {
      return;
    }
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.attachLink,
        content: { setAttachList: addAttachLink },
      })
    );
  };

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={t("attachmentFilesAndLinks.add_link")}
        open={Boolean(drawer)}
        onClose={handleCancel}
        actions={
          <Stack flexDirection="row" justifyContent="flex-end" gap={4}>
            <Button
              variant="outlined"
              onClick={handleCancel}
              color="secondary"
              size="large"
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={onSubmit}
              disabled={!links?.length}
              loading={isLoading}
            >
              {t("add")}
            </Button>
          </Stack>
        }
      >
        <Wrapper>
          <form onSubmit={onSubmit} noValidate ref={ref}>
            <Grid container spacing={4} sx={{ mb: 6 }}>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  color="primary.main"
                  mb={3}
                >
                  {tAttachments("drawer.purpose_link")}
                </Typography>

                <FormRadio
                  name="fileAppointment"
                  control={control}
                  values={assignmentOptions}
                  className="radio__buttons"
                  flexDirection="column"
                  fullWidth={false}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4} sx={{ mt: 4 }}>
              <Grid item xs={12}>
                <Input
                  label={t("attachmentFilesAndLinks.description")}
                  variant="outlined"
                  size="medium"
                  maxLength={500}
                  {...register("additionalInformation")}
                />
              </Grid>
            </Grid>
            <Stack
              mt={4}
              sx={{
                opacity:
                  getValues("links")?.length === 10 || !fileAppointment
                    ? 0.5
                    : 1,
              }}
            >
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={handleAttachLink}
              >
                <Icon
                  size={18}
                  name="Link"
                  color={theme.palette.secondary.main}
                />
                <Typography fontSize={13}>
                  {t("attachmentFilesAndLinks.add_link")}
                </Typography>
              </Button>
            </Stack>

            <AttachedLink control={control} setValue={setValue} />
          </form>
        </Wrapper>
      </Drawer>
    </>
  );
};
