import React from "react";
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { setModal, useAppDispatch } from "app";
import { Icon } from "ui-kit";

import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import {
  FileAttachedToCorrespondenceFileStatusEnum,
  FileAttachedToCorrespondenceTypeOfAttachmentEnum,
  IAttachLink,
  IncomingDocumentEntity,
  ModalVariants,
} from "@types";

import { AttachedFile } from "./AttachedFile";
import { AttachedLink } from "./AttachedLink";
import { FILES_MAX_COUNT } from "./constants";
import { CardTitleWrapper, CardWrapper } from "./styles";

interface Props {
  form: {
    control: Control<IncomingDocumentEntity>;
    setValue: UseFormSetValue<IncomingDocumentEntity>;
    getValues: UseFormGetValues<IncomingDocumentEntity>;
  };
}

export const AttachmentAndSending = ({
  form: { control, setValue, getValues },
}: Props) => {
  const { t } = useTranslation("tasks");
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { files = [] } = useWatch<IncomingDocumentEntity>({ control });

  const addAttachLink = (link: IAttachLink) => {
    const newList = getValues("links") || [];
    newList.push(link);
    setValue("links", newList);
  };

  const addFile = (
    file: File[],
    typeOfAttachment: FileAttachedToCorrespondenceTypeOfAttachmentEnum,
    fileStatusAttachment: FileAttachedToCorrespondenceFileStatusEnum
  ) => {
    const filesList = getValues("files") || [];
    const typeOfAttachmentList = getValues("typeOfAttachment") || [];
    const fileStatusAttachmentList = getValues("fileStatusAttachment") || [];

    filesList.push(file[0]);
    typeOfAttachmentList.push(typeOfAttachment);
    fileStatusAttachmentList.push(fileStatusAttachment);

    setValue("files", filesList || [], { shouldDirty: true });
    setValue("typeOfAttachment", typeOfAttachmentList || [], {
      shouldDirty: true,
    });
    setValue("fileStatusAttachment", fileStatusAttachmentList || [], {
      shouldDirty: true,
    });
  };

  const handleAddFile = () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.addFileToIncomingDocument,
        content: { onAddFile: addFile },
      })
    );
  };

  const handleAttachLink = () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.attachLink,
        content: { setAttachList: addAttachLink },
      })
    );
  };

  return (
    <CardWrapper>
      <CardTitleWrapper>
        <Typography variant="h9_semiBold" color="text.disabled">
          {t("append")}
        </Typography>

        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={2}
        >
          <IconButton
            size="small"
            sx={{
              borderRadius: 2,
              gap: 1,
              opacity: getValues("links")?.length === 10 ? 0.5 : 1,
            }}
            onClick={handleAttachLink}
            disabled={Boolean(getValues("links")?.length === 10)}
          >
            <Icon size={18} name="Link" color={theme.palette.secondary.main} />
            <Typography fontSize={13} fontWeight={600} color="secondary.main">
              {t("add.link")}
            </Typography>
          </IconButton>

          <IconButton
            size="small"
            sx={{
              borderRadius: 2,
              gap: 1,
              opacity: !files ? 1 : files.length < FILES_MAX_COUNT ? 1 : 0.5,
            }}
            onClick={handleAddFile}
          >
            <Icon
              name="Paperclip"
              size={18}
              color={theme.palette.secondary.main}
            />
            <Typography fontSize={13} fontWeight={600} color="secondary.main">
              {t("add.file")}
            </Typography>
          </IconButton>
        </Stack>
      </CardTitleWrapper>

      <AttachedFile control={control} setValue={setValue} />
      <AttachedLink control={control} setValue={setValue} />
    </CardWrapper>
  );
};
