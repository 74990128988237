import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useGetDocumentLinks, useUploadExternalLinkMutation } from "api";
import { Modal, Table, useTableQueryMethods, useTableQuerySet } from "ui-kit";

import { Stack } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { getLocaleDateFormat } from "@sbm/fe-utils";
import { Button, FormRadio, FormSelect, Input } from "@sbm/ui-components";
import {
  DocumentTypeOfTheDocumentEnum,
  IAttachedDocumentLink,
  IAttachLinks,
  LinksTypeEnum,
} from "@types";

import { useDebounce, useQueryParams } from "../../../../hooks";
import {
  getAttachLinkTableColumns,
  ATTACHED_LINKS_FILTERABLE_FIELDS,
} from "./constant";
import { getColumnNameFromArray, getFilterValues } from "./helpers";

const DEFAULT_SORT_MODEL = "createdAt:DESC";

export const AttachLinkPopup = (props: {
  open: boolean;
  onClose: () => void;
  data: {
    setAttachList: (
      e: IAttachedDocumentLink | { documentName: string; id: number }
    ) => void;
  };
}) => {
  const {
    open,
    onClose,
    data: { setAttachList },
  } = props;
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [sortModel, setSortModel] = useState(DEFAULT_SORT_MODEL);
  const { get, remove } = useQueryParams();

  const { setValue, register, control } = useForm<IAttachLinks>({
    defaultValues: {
      linkType: LinksTypeEnum.internalLink,
    },
  });

  const { documentType, linkType, externalLinkUrl } = useWatch({ control });

  const debouncedSearch = useDebounce(search);
  const filterFromUrl = get("filter", true) as string[];

  const filtersToSend =
    filterFromUrl?.length > 0
      ? getFilterValues(filterFromUrl, t)
      : filterFromUrl;

  const filterableFields = React.useMemo(() => {
    return ATTACHED_LINKS_FILTERABLE_FIELDS.map((item) => ({
      ...item,
      title: getColumnNameFromArray(item.title, t),
    }));
  }, [t]);

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetDocumentLinks(
      filtersToSend,
      documentType,
      sortModel,
      debouncedSearch
    );

  const handleAttachLink = (row: GridRowParams) => {
    setAttachList(row.row);
    remove("filter");
    remove("sortBy");
    onClose();
  };

  useTableQuerySet({
    sortModel,
    search,
    setSortModel,
    setSearch,
  });

  const { onSortModelChange, onSearchChange } = useTableQueryMethods({
    setSortModel,
    setSearch,
  });

  const handleRowClick = (row: GridRowParams) => {
    handleAttachLink(row);
  };

  const documentTypesArray = Object.keys(DocumentTypeOfTheDocumentEnum).map(
    (key) => ({
      option: t(
        DocumentTypeOfTheDocumentEnum[
          key as keyof typeof DocumentTypeOfTheDocumentEnum
        ]
      ),
      value:
        DocumentTypeOfTheDocumentEnum[
          key as keyof typeof DocumentTypeOfTheDocumentEnum
        ],
    })
  );

  const rows: IAttachedDocumentLink[] = useMemo(
    () =>
      data?.pages?.flatMap((page) => {
        return page.items.map((item, index) => {
          return {
            ...item,
            key: item.internalNumber,
            id: item.internalNumber,
            sequenceNumber:
              (page.meta.currentPage - 1) * page.meta.itemsPerPage + index + 1,
            longNameOfTheDocument: item.documentName,
            createdAt: getLocaleDateFormat(item.createdAt),
            documentNumber: item.documentNumber,
            context: item.contextOfTheDocument,
            documentDate: item.documentDate,
          };
        });
      }) || [],
    [data]
  );

  const { mutate: uploadExternalLink } = useUploadExternalLinkMutation(
    (data) => {
      setAttachList({
        documentName: data.link,
        id: data.documentId,
      });
      onClose();
    },
    (error) => {
      console.error("Error occurred:", error);
    }
  );

  const handleAttachExternalLink = () => {
    const payload = { link: externalLinkUrl };
    uploadExternalLink(payload);
  };

  const totalItems = data?.pages[0].meta.totalItems ?? 0;

  const columns: GridColDef[] = useMemo(
    () => getAttachLinkTableColumns(t),
    [t]
  );

  const genderOptions = [
    { option: t("internal_link"), value: LinksTypeEnum.internalLink },
    { option: t("external_link"), value: LinksTypeEnum.externalLink },
  ];

  useEffect(() => {
    if (linkType) {
      setValue("documentType", undefined);
      setValue("externalLinkUrl", "");
      remove("filter");
      remove("sortBy");
    }
  }, [linkType, setValue, remove]);

  const content = {
    title: t("messages.attach_link"),
    body: (
      <Stack gap={6}>
        <FormRadio
          name="linkType"
          control={control}
          values={genderOptions}
          className="radio__buttons"
          flexDirection="row"
        />
        {linkType === LinksTypeEnum.internalLink ? (
          <>
            <Stack
              width={documentType ? 600 : "100%"}
              mb={documentType ? 0 : 6}
            >
              <FormSelect
                label={t("messages.document_type")}
                name="documentType"
                size="medium"
                variant="outlined"
                required
                values={documentTypesArray}
                control={control}
              />
            </Stack>

            {documentType ? (
              <Table
                rows={rows}
                columns={columns}
                rowCount={totalItems}
                loading={isLoading || isFetchingNextPage}
                hasNextPage={hasNextPage}
                filterableFields={filterableFields}
                fetchNextPage={fetchNextPage}
                onSearchOptionChange={onSearchChange}
                onSortModelChange={onSortModelChange}
                sortModel={sortModel}
                onRowClick={handleRowClick}
                hasToolbar
                hideFooter
              />
            ) : null}
          </>
        ) : (
          <Stack width="100%">
            <Input
              label={t("link")}
              variant="outlined"
              color="secondary"
              size="medium"
              required
              maxLength={500}
              {...register("externalLinkUrl")}
            />
          </Stack>
        )}
      </Stack>
    ),
    mainButton:
      linkType === LinksTypeEnum.externalLink ? (
        <Button
          autoFocus
          variant="contained"
          color="secondary"
          onClick={handleAttachExternalLink}
          disabled={!externalLinkUrl?.trim().length}
        >
          {t("confirm")}
        </Button>
      ) : null,
  };

  const handleClose = () => {
    remove("filter");
    remove("sortBy");
    onClose();
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      content={content}
      sizeLarge={Boolean(documentType)}
      updatedVersion
    />
  );
};
