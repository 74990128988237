import styled from "styled-components";
import { FLEX_ROW_FULL } from "ui-kit";

import male_avatar from "../../../../../../assets/images/avatars/winter_male_avatar.png";

export const CorrespondentAvatarWrapper = styled.div<{
  $fromSystem?: boolean;
}>`
  ${FLEX_ROW_FULL};
  width: 40px;
  height: 40px;
  color: ${({ theme, $fromSystem }) =>
    $fromSystem ? theme.palette.warning.dark : theme.palette.text.black};
  border-color: ${({ theme, $fromSystem }) =>
    $fromSystem ? theme.palette.warning.dark : theme.palette.primary.main};
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
`;

export const ActivityIndicator = styled.div`
  position: absolute;
  height: 12px;
  width: 12px;
  border: 2px solid white;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.success.main};
  margin: 28px 0 0 32px;
`;

export const AvatarWrapper = styled.div<{
  $fromSystem?: boolean;
}>`
  border-radius: 50%;
  background-image: ${({ $fromSystem }) =>
    $fromSystem ? `url(${male_avatar})` : ``};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
`;
