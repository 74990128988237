import toast from "react-hot-toast";

import { axiosService } from "app";
import { AxiosError } from "axios";

import { getAPIErrorMessage } from "@sbm/fe-utils";

export const fetchLinkToFile = async (filename: string | undefined) => {
  if (!filename) return "";

  const { data } = await axiosService({
    endpoint: `contract-attached-files/get-link`,
    body: { filename },
  });

  return data;
};

export const uploadExternalLink = async (body: {
  link: string | undefined;
}) => {
  try {
    const response = await axiosService({
      endpoint: `external-links`,
      method: "POST",
      body,
    });

    return response.data;
  } catch (error) {
    const errorMSG = getAPIErrorMessage(error as AxiosError);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }
};
