import toast from "react-hot-toast";

import { uploadExternalLink } from "api";
import { AxiosError } from "axios";

import { getAPIErrorMessage } from "@sbm/fe-utils";
import { useMutation } from "@tanstack/react-query";

export const useUploadExternalLinkMutation = (
  onSuccess: (data: any) => void,
  onError: (error: any) => void
) => {
  return useMutation(
    async (body: { link: string | undefined }) => {
      const response = await uploadExternalLink(body);
      return response;
    },
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        const errorMsg = getAPIErrorMessage(e as AxiosError);
        toast.error(errorMsg);
        onError(e);
      },
    }
  );
};
