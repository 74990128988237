import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useEditDeleteCorrespondenceAttachedFileMutation } from "api";
import {
  axiosService,
  closeDrawerWithData,
  setModal,
  TransitionPrompt,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer, Icon } from "ui-kit";

import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { Button, FormRadio, Input } from "@sbm/ui-components";
import {
  CorrespondenceTypeEnum,
  FileAttachedToCorrespondenceEditTypeEnum,
  FileAttachedToCorrespondenceFileStatusEnum,
  FileAttachedToCorrespondenceTypeOfAttachmentEnum,
  ModalVariants,
} from "@types";

import { Wrapper } from "../styles";
import { IAttachesTaskFileForm } from "./constants";
import { createRequestBody } from "./helpers";
import { PreviewFile } from "./PreviewFile";

interface Props {
  correspondenceId: number | undefined;
  fileId: number | undefined;
  type: CorrespondenceTypeEnum;
}

export const EditCorrAttachedFileForm = ({
  correspondenceId,
  fileId,
  type,
}: Props) => {
  const { t: tAttachments } = useTranslation("attachments");
  const { t } = useTranslation("correspondence");

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const ref = React.useRef<HTMLFormElement | null>(null);

  const { drawerWithData } = useAppSelector((state) => state.global);

  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const additionalInformation =
    drawerWithData?.content.data.additionalInformation;
  const typeOfAttachment = drawerWithData?.content.data.typeOfAttachment;
  const fileNameForSystem = drawerWithData?.content.data.fileNameForSystem;
  const fileFormat = drawerWithData?.content.data.fileFormat;
  const fileStatus = drawerWithData?.content.data.fileStatus;

  const [showPrompt, setShowPrompt] = useState(false);

  const { reset, register, handleSubmit, control, getValues, setValue } =
    useForm<IAttachesTaskFileForm>({
      defaultValues: {
        fileAppointment: typeOfAttachment,
        descriptionForAttachment: additionalInformation,
        fileStatus: fileStatus,
      },
    });
  const fields = useWatch({ control });

  const { descriptionForAttachment } = fields;

  const isOutgoing = type === CorrespondenceTypeEnum.outgoingDocument;
  const isServiceNote = type === CorrespondenceTypeEnum.serviceNote;

  const handleCloseDrawer = () => {
    reset();
    dispatch(closeDrawerWithData());
  };

  const handleClose = () => {
    handleCloseDrawer();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCancel = () => {
    if (additionalInformation !== descriptionForAttachment) {
      setShowPrompt(true);
      return;
    }
    handleClose();
  };

  const handleConfirmTransition = () => {
    reset();
    handleClose();
  };

  const { mutate: editDeleteFile, isLoading } =
    useEditDeleteCorrespondenceAttachedFileMutation(
      handleConfirmTransition,
      handleConfirmTransition
    );

  const assignmentOptions = [
    {
      option: t("attachmentFilesAndLinks.main_document"),
      value: FileAttachedToCorrespondenceTypeOfAttachmentEnum.mainDocument,
      disabled: true,
    },
    {
      option: t("attachmentFilesAndLinks.attachment"),
      value: FileAttachedToCorrespondenceTypeOfAttachmentEnum.appendix,
      disabled: true,
    },
    {
      option: t("attachmentFilesAndLinks.auxiliary_file"),
      value: FileAttachedToCorrespondenceTypeOfAttachmentEnum.supportingFile,
      disabled: true,
    },
  ];

  const typesOptions = [
    {
      option: t("attachmentFilesAndLinks.signed_document"),
      value: FileAttachedToCorrespondenceFileStatusEnum.signedDocument,
      disabled: true,
    },
    {
      option: t("attachmentFilesAndLinks.draft"),
      value: FileAttachedToCorrespondenceFileStatusEnum.draftDocument,
      disabled: true,
    },
  ];

  const onSubmit = handleSubmit(async (body) => {
    if (!correspondenceId || !fileId) return;
    const requestBody = createRequestBody({ ...body, fileId });
    editDeleteFile({
      requestBody,
      correspondenceId,
      type: FileAttachedToCorrespondenceEditTypeEnum.edit,
    });
  });

  useEffect(() => {
    const fetchPreviewUrl = async () => {
      try {
        const { data: linkToFile } = await axiosService({
          endpoint: `contract-attached-files/get-link`,
          body: { filename: fileNameForSystem },
        });

        setPreviewUrl(linkToFile);
      } catch (error) {
        console.error(
          error,
          "[ERROR] - ContractAttachmentsContainer - handleCellClick "
        );
      } finally {
        setLoading(false);
      }
    };

    if (fileNameForSystem) {
      setLoading(true);
      fetchPreviewUrl();
    }
  }, [fileNameForSystem, setLoading, setPreviewUrl]);

  const handleDelete = () => {
    const requestBody = new FormData();
    requestBody.append("fileAttachedToCorrespondenceId", String(fileId));

    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.removeCorrespondenceFile,
        content: {
          data: {
            onRemove: () =>
              editDeleteFile({
                requestBody,
                correspondenceId: correspondenceId!,
                fileId: fileId!,
                type: FileAttachedToCorrespondenceEditTypeEnum.delete,
              }),
          },
        },
      })
    );
  };

  return (
    <>
      {showPrompt && (
        <TransitionPrompt
          open={showPrompt}
          onClose={handleClosePrompt}
          onConfirm={handleCloseDrawer}
        />
      )}

      <Drawer
        title={tAttachments("edit.file")}
        open={Boolean(drawerWithData)}
        onClose={handleCancel}
        actions={
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack>
              {
                <Button
                  variant="text"
                  size="medium"
                  color="secondary"
                  sx={{ fontSize: "15px" }}
                  startIcon={
                    <Icon name="Trash2" color={theme.palette.secondary.main} />
                  }
                  loading={isLoading}
                  onClick={handleDelete}
                >
                  {t("delete")}
                </Button>
              }
            </Stack>
            <Stack flexDirection="row" justifyContent="flex-end" gap={4}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                color="secondary"
                size="large"
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={onSubmit}
                disabled={additionalInformation === descriptionForAttachment}
                loading={isLoading}
              >
                {t("add")}
              </Button>
            </Stack>
          </Stack>
        }
      >
        <Wrapper>
          <form onSubmit={onSubmit} noValidate ref={ref}>
            <Grid container spacing={4} sx={{ mb: 6 }}>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  color="primary.main"
                  mb={3}
                >
                  {tAttachments("drawer.purpose")}
                </Typography>

                <FormRadio
                  name="fileAppointment"
                  control={control}
                  values={assignmentOptions}
                  className="radio__buttons"
                  flexDirection="column"
                  fullWidth={false}
                />
              </Grid>
              {(isOutgoing || isServiceNote) && (
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    color="primary.main"
                    mb={3}
                  >
                    {t("attachmentFilesAndLinks.file_type")}
                  </Typography>

                  <FormRadio
                    name="fileStatus"
                    control={control}
                    values={typesOptions}
                    className="radio__buttons"
                    flexDirection="column"
                    fullWidth={false}
                  />
                </Grid>
              )}
            </Grid>
            <PreviewFile
              previewUrl={previewUrl || ""}
              fileFormat={fileFormat || ""}
              loading={loading}
            />
            <Grid container spacing={4} sx={{ mt: 4 }}>
              <Grid item xs={12}>
                <Input
                  label={t("attachmentFilesAndLinks.description")}
                  variant="outlined"
                  size="medium"
                  {...register("descriptionForAttachment")}
                />
              </Grid>
            </Grid>
          </form>
        </Wrapper>
      </Drawer>
    </>
  );
};
