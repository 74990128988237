import styled from "styled-components";
import {
  FLEX_COLUMN_FULL,
  FLEX_ROW,
  FLEX_ROW_ALIGN_CENTER,
  FLEX_ROW_FULL,
} from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const ApprovalTabsWrapper = styled.div`
  ${FLEX_ROW};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(4, 1)};
`;
export const UnViewedTasksCount = styled.div`
  ${FLEX_COLUMN_FULL};
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  box-shadow: ${({ theme }) => theme.shadows[4]};
  padding: ${({ theme }) => theme.spacing(0.5, 1)};
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
`;

export const ApprovalTab = styled.div<{ $isActive: boolean }>`
  ${FLEX_ROW};
  gap: ${({ theme }) => theme.spacing(2)};

  position: relative;

  padding: ${({ theme }) => theme.spacing(2, 4)};

  color: ${({ theme, $isActive }) =>
    $isActive ? theme.palette.secondary.main : theme.palette.text.secondary};
  border-bottom: 2px solid
    ${({ theme, $isActive }) =>
      $isActive ? theme.palette.secondary.main : "inherit"};
`;

export const TaskViewingIndicatorWrapper = styled.div<{ $isViewed: boolean }>`
  ${FLEX_ROW_FULL};
  gap: ${({ theme }) => theme.spacing()};
  overflow: visible !important;
  .read_unread_icon {
    opacity: ${({ $isViewed }) => ($isViewed ? 0 : 1)};
    background-color: ${({ $isViewed, theme }) =>
      $isViewed ? "" : theme.palette.primary.dark};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.4s ease;
    &:hover {
      opacity: 1;
      background-color: ${({ $isViewed, theme }) =>
        $isViewed ? theme.palette.primary.light : theme.palette.primary.dark};
    }
  }
`;

export const CellWrapper = styled.div<{ $isTaskViewed: boolean }>`
  ${FLEX_ROW_ALIGN_CENTER};
  gap: ${({ theme }) => theme.spacing()};

  font-weight: ${({ $isTaskViewed }) => ($isTaskViewed ? 400 : 700)};
`;

export const TableWrapper = styled.div`
  .highlighted-row {
    background-color: ${({ theme }) =>
      hexToRgba(theme.palette.error.main, 0.08)};
    &:hover {
      background-color: ${({ theme }) =>
        hexToRgba(theme.palette.error.main, 0.08)};
    }
  }
`;
