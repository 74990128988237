import { UI_CONFIG } from "app";

import {
  Drawer as MuiDrawer,
  List as MuiList,
  styled,
  Theme,
  CSSObject,
} from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

const drawerStyles = {
  overflowX: "hidden",
  backgroundColor: "transparent",
  zIndex: 1,
} as CSSObject;

export const openedMixin = (theme: Theme): CSSObject => ({
  ...drawerStyles,
  width: UI_CONFIG.drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

export const closedMixin = (theme: Theme): CSSObject => ({
  ...drawerStyles,
  width: UI_CONFIG.drawerMobileWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: UI_CONFIG.drawerWidth,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      marginTop: "0 !important",
      borderRight: "none",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      marginTop: "0 !important",
      borderRight: "none",
    },
  }),
}));

export const SidebarWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  ".instruction__link": {
    textDecoration: "none",
    "&:hover, &:active": {
      textDecoration: "none",
      cursor: "pointer",
    },
  },
}));

export const ListWrapper = styled(MuiList)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
  padding: theme.spacing(12, 0, 4),
}));

export const ArrowWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 24,
  right: -12,
  width: 24,
  height: 24,

  backgroundColor: theme.palette.background.default,
  borderTopRightRadius: 4,

  transform: "rotate(-135deg)",
  zIndex: theme.zIndex.tooltip * 10,
  cursor: "pointer",

  svg: {
    position: "relative",
    right: -8,
    transform: "rotate(135deg)",
  },
  "&:hover": {
    boxShadow: `0 0 4px 0 ${theme.palette.background.default}`,
  },
}));

export const Logo = styled("div")(({ theme }) => ({
  height: `${UI_CONFIG.header}px`,
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 4),
}));

export const Instruction = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  margin: theme.spacing(2, 4),
  gap: theme.spacing(4),
  borderRadius: theme.spacing(2),
  minHeight: "40px",
  // backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: hexToRgba(theme.palette.primary.contrastText, 0.04),
  },
}));
