import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_COLUMN_FULL, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { Card } from "@mui/material";

export const Wrapper = styled(Card)<{ $disabled?: boolean }>`
  padding: ${({ theme }) => theme.spacing(4)};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};

  .approvals__container {
    ${FLEX_ROW_ALIGN_CENTER};
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing(4)};
    padding-right: ${({ theme }) => theme.spacing(4)};
  }

  .approvals__content {
    ${FLEX_ROW_ALIGN_CENTER};
    width: 100%;
    gap: ${({ theme }) => theme.spacing(2)};
  }
`;

export const TaskWidgetItem = styled(Card)<{ $bgColor: string }>`
  ${FLEX_BETWEEN};
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ theme }) => theme.palette.common.white};
  width: 100%;
  height: 146px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
`;

export const IconWrapper = styled.div<{ $bgColor: string }>`
  ${FLEX_COLUMN_FULL};
  background-color: ${({ $bgColor }) => $bgColor};
  height: 32px;
  width: 32px;
  border-radius: ${({ theme }) => theme.spacing()};
`;

export const DocumentWidgetItem = styled.div<{ $bgColor: string }>`
  ${FLEX_COLUMN_FULL};
  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: 4px;
  width: 76px;
  height: 76px;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

export const WidgetLeftWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
  justify-content: start;
  margin: ${({ theme }) => theme.spacing(2)};
  height: 100%;
`;

export const ContentWrapper = styled.div`
  border-right: 4px solid;
  border-color: ${({ theme }) => theme.palette.secondary.main};
  height: 100%;
`;
