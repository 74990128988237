import { IContractForm, PaymentTypeEnum, TParty } from "app";

export const InitialPartyFields: TParty = {
  organizationId: undefined,
  roleContractPartyId: undefined,
  legalEntity: true,
  operatesOnBasis: "",
};

export const InitialPaymentOrderAdvance = {
  prepaymentConditionId: undefined,
  percentagePrepaymentFromContractPrice: undefined,
  paymentWithoutVat: undefined,
  paymentWithVat: undefined,
  paymentOrderVatPercentage: 20,
  paymentType: PaymentTypeEnum.advance,
};

export const InitialPaymentOrderPostpayment = {
  postpaymentConditionId: undefined,
  percentagePrepaymentFromContractPrice: undefined,
  paymentWithoutVat: undefined,
  paymentWithVat: undefined,
  paymentOrderVatPercentage: 20,
  paymentType: PaymentTypeEnum.postpayment,
};

export const ContractDefaultValues: IContractForm = {
  contractCategoryId: "",
  contractTypeId: "",
  contractSubtypeId: "",
  parties: [InitialPartyFields, InitialPartyFields] as TParty[],
  contractAssignee: undefined,
  internalNumber: "",
  pricingContractId: "",
  numberContract: undefined,
  dateContract: undefined,
  dateOfStatusChanging: undefined,
  contractSubtypeTypicalSubjectOfTheContractId: "",
  contractSubjectDetails: "",
  dateExecutionBeginningContract: undefined,
  dateExecutionExpiryContract: undefined,
  dateValidityBeginningContract: undefined,
  dateValidityExpiryContract: undefined,
  priceContractWithoutVat: undefined,
  vatPercentage: 20,
  priceVat: undefined,
  priceTotal: 0,
  percentagePrepaymentFromContractPrice: undefined,
  prepaymentAmountOfContract: undefined,
  advancePaymentWithoutVat: undefined,
  paymentOrderVatPercentage: undefined,
  advancePaymentVat: undefined,
  prepaymentTotal: 0,
  cfaDepartment: "",
  contractSupervisor: "",
  statusContract: undefined,
  subjectContractAdditionalInformation: "",
  conditionExecutionBeginningContractId: "",
  conditionExecutionExpiryContractId: "",
  conditionValidityExpiryContractId: "",
  conditionValidityBeginningContractId: "",
  paymentOrders: [InitialPaymentOrderAdvance, InitialPaymentOrderPostpayment],
  presigned: false,
};
