import styled from "styled-components";

export const Wrapper = styled.div`
  .chip_with_avatar {
    span {
      padding-left: ${({ theme }) => theme.spacing()};
    }
  }
`;

export const AvatarWrapper = styled.div<{ $avatar?: string }>`
  background-image: ${({ $avatar }) => `url(${$avatar})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  width: 23px;
  border-radius: 50%;
`;

export const ChipAvatar = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing()};
`;
