import { IAttachesTaskFileForm } from "./constants";

export const createRequestBody = (body: IAttachesTaskFileForm) => {
  const formData = new FormData();

  formData.append("additionalInformation", body.descriptionForAttachment || "");
  if (body.fileId) {
    formData.append("fileAttachedToCorrespondenceId", `${body.fileId}`);
  }

  return formData;
};
