import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_COLUMN_FULL } from "ui-kit";

export const Wrapper = styled.div`
  position: relative;
`;
export const TitleWrapper = styled.div<{ $disabled?: boolean }>`
  ${FLEX_BETWEEN};
  margin-bottom: ${({ theme }) => theme.spacing(8)};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  .widgetTitle {
    &:hover {
      cursor: pointer;
    }
  }
`;
export const CountWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: ${({ theme }) => theme.shadows[4]};
  margin: ${({ theme }) => theme.spacing(-1, -7, 0, 0)};
`;
