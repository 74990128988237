import { IEmployee, IPersonalData } from "@types";

export type TOrganizationIdAndPosition = { id: number; position: string };

export const getUserEmployeeOrganizationsAndPositions = (
  personalData: IPersonalData | undefined
): TOrganizationIdAndPosition[] => {
  if (!personalData?.employees) return [];

  return personalData.employees
    .filter((i) => i.validity)
    .map((i) => {
      return {
        id: i.staffUnit?.organizationId,
        position: i.staffUnit?.position?.namePosition,
      };
    }) as TOrganizationIdAndPosition[];
};

export const getUserExternalSubstitutionOrganizationsAndPositions = (
  personalData: IPersonalData | undefined
): TOrganizationIdAndPosition[] => {
  if (!personalData?.employees) return [];

  let externalSubstitutions: IEmployee[] = [];

  personalData.employees.forEach((i) => {
    if (i.externalSubstitutions && i.externalSubstitutions.length > 0) {
      externalSubstitutions = [
        ...externalSubstitutions,
        ...i.externalSubstitutions,
      ];
    }
  });

  return externalSubstitutions
    .filter((i) => i.validity)
    .map((i) => {
      return {
        id: i.staffUnit?.organizationId,
        position: i.staffUnit?.position?.namePosition,
      };
    }) as TOrganizationIdAndPosition[];
};
