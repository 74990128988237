import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Stack } from "@mui/material";
import { Button } from "@sbm/ui-components";

export const ContractSetUpApprovalPopup = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const content = {
    title: t("approval_popup_text"),
    body: <Stack gap={3}></Stack>,
    mainButton: (
      <Button autoFocus variant="contained" color="secondary" onClick={onClose}>
        {t("close")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
