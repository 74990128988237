import React from "react";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { DefaultTFuncReturn } from "i18next";
import { SxProps, Theme } from "@mui/material";

type TSize = "small" | "medium";

export interface FormRadioProps {
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  label?: string | DefaultTFuncReturn;
  name: string;
  size?: TSize;
  control?: Control<any>;
  values: { value: string | boolean; option: string | DefaultTFuncReturn | JSX.Element; disabled?: boolean }[];
  helperText?: string;
  className?: string;
  flexDirection?: "row" | "column";
  onChangeListener?: (val: string | boolean) => void;
  justifyContent?: "flex-start" | "space-between";
  alignItems?: "flex-start";
  labelPropsSx?: SxProps<Theme>;
  formControlLabelPropsSx?: SxProps<Theme>;
}

export const FormRadio = React.forwardRef((props: FormRadioProps, ref) => {
  const {
    fullWidth = true,
    label,
    values,
    required = false,
    disabled,
    error,
    errorText,
    control,
    onChangeListener,
    name,
    size = "small",
    className,
    flexDirection = "row",
    justifyContent = "flex-start",
    alignItems,
    labelPropsSx = {},
    formControlLabelPropsSx = {},
  } = props;

  if (!values || !values.length) return null;

  return (
    <FormControl fullWidth={fullWidth} disabled={disabled} required={required} error={error}>
      <FormLabel id={label || ""} sx={{ ...labelPropsSx }}>
        {label}
      </FormLabel>

      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field }) => {
          return (
            <RadioGroup
              {...field}
              className={className}
              sx={{ flexDirection, justifyContent, alignItems }}
              value={field.value === false ? false : field.value || ""}
              onChange={(e) => {
                field?.onChange?.(e);
                onChangeListener?.(field.value);
              }}
            >
              {values.map(({ value, option, disabled }) => {
                return (
                  <FormControlLabel
                    key={`${option} + ${value}`}
                    label={option}
                    value={value}
                    disabled={disabled}
                    sx={{ ...formControlLabelPropsSx }}
                    control={<Radio color="secondary" size={size} sx={{ flex: 1 }} />}
                  />
                );
              })}
            </RadioGroup>
          );
        }}
      />

      {error ? <FormHelperText>{errorText}</FormHelperText> : null}
    </FormControl>
  );
});
