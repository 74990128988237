import React, { useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Modal } from "ui-kit";

import { Grid, Stack, Typography } from "@mui/material";
import { Button, Input } from "@sbm/ui-components";

interface IApprovalRoute {
  duration?: number;
  queueNumber?: number;
  rowId?: number;
}

export const ChangeApprovalRoutePopup = ({
  open,
  onClose,
  data,
}: {
  open: boolean;
  onClose: () => void;
  data: {
    data: {
      duration: number;
      queueNumber: number;
      type: "queue" | "duration";
      changeDuration: (duration: number) => number;
    };
  };
}) => {
  const { t } = useTranslation("correspondence");
  const { t: tCommon } = useTranslation();

  const { duration, queueNumber, type, changeDuration } = data.data;

  const { register, handleSubmit, getValues, setValue, control } =
    useForm<IApprovalRoute>({
      defaultValues: {
        duration: duration,
        queueNumber: queueNumber,
      },
    });
  const { duration: routeDuration } = useWatch({ control });

  const onSubmit = handleSubmit(async (data) => {
    const newDuration = getValues("duration") || 2;
    changeDuration(newDuration);
    onClose();
  });

  const isDisabled = useMemo(() => {
    switch (type) {
      case "queue":
        return getValues("queueNumber");
      case "duration":
        if (routeDuration) {
          if (+routeDuration === +duration) {
            return false;
          }
          return Boolean(+routeDuration > 0 && +routeDuration !== +duration);
        }
        return false;
      default:
        return false;
    }
  }, [type, getValues, duration, routeDuration]);

  const content = {
    modalTitle: type === "queue" ? t("change_queue") : t("change_duration"),
    body: (
      <Stack mb={6}>
        <Grid container spacing={2}>
          {type === "queue" ? (
            <h1>queue</h1>
          ) : (
            <Grid item xs={12}>
              <Input
                variant="outlined"
                size="medium"
                label={t("duration")}
                type="text"
                {...register("duration")}
                onBeforeInput={(event) => {
                  const inputEvent = event.nativeEvent as InputEvent;
                  if (!/^\d$/.test(inputEvent.data || "")) {
                    event.preventDefault();
                  }
                }}
                onChange={(event) => {
                  const sanitizedValue = event.target.value.replace(
                    /^0+(?=\d)/,
                    ""
                  );
                  const numericValue = sanitizedValue
                    ? parseInt(sanitizedValue, 10)
                    : 0;
                  setValue("duration", numericValue, { shouldDirty: true });
                }}
              />
              {routeDuration && routeDuration > 0 ? null : (
                <Typography variant="body3" fontWeight={500} color="error.main">
                  {tCommon("duration_warning_message")}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Stack>
    ),

    mainButton: (
      <Stack flexDirection="row" justifyContent="end" width="100%" gap={2}>
        <Button
          autoFocus
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          {t("cancel")}
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="secondary"
          disabled={!isDisabled}
          onClick={onSubmit}
        >
          {t("add")}
        </Button>
      </Stack>
    ),
  };

  return (
    <Modal isOpen={open} onClose={onClose} content={content} updatedVersion />
  );
};
