import React from "react";
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import { Stack } from "@mui/material";
import { OutgoingDocumentEntity } from "@types";

import { DetailsStepActor } from "../DetailsStepActor";
import { DetailsStepAdditionalInfo } from "../DetailsStepAdditionalInfo";
import { DetailsStepDocumentContent } from "../DetailsStepDocumentContent";
import { RegistrationArea } from "../DetailsStepRegistration";
import { DetailsStepSender } from "../DetailsStepSender";

export interface DetailsStepProps {
  control: Control<OutgoingDocumentEntity>;
  setValue: UseFormSetValue<OutgoingDocumentEntity>;
  getValues: UseFormGetValues<OutgoingDocumentEntity>;
  register: UseFormRegister<OutgoingDocumentEntity>;
  errors: FieldErrors<OutgoingDocumentEntity>;
  isEditMode: boolean;
}

export const DetailsStep: React.FC<DetailsStepProps> = ({
  control,
  setValue,
  register,
  errors,
  getValues,
  isEditMode,
}) => {
  return (
    <Stack gap={4}>
      <RegistrationArea
        control={control}
        setValue={setValue}
        register={register}
        errors={errors}
      />

      <DetailsStepSender
        control={control}
        setValue={setValue}
        register={register}
        errors={errors}
        isEditMode={isEditMode}
      />

      <DetailsStepActor
        control={control}
        setValue={setValue}
        register={register}
        errors={errors}
        isEditMode={isEditMode}
      />

      <DetailsStepDocumentContent
        control={control}
        setValue={setValue}
        register={register}
        errors={errors}
        getValues={getValues}
      />

      <DetailsStepAdditionalInfo
        control={control}
        setValue={setValue}
        isEditMode={isEditMode}
      />
    </Stack>
  );
};
