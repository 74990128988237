import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useCreateServiceNoteMutation,
  useEditCorrespondenceMutation,
} from "api";
import {
  closeDrawer,
  getUserEmployeeOrganizationsAndPositions,
  getUserExternalSubstitutionOrganizationsAndPositions,
  resetLetterCreationStep,
  setChangeLetterCreationStep,
  setModal,
  TransitionPrompt,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer, Icon } from "ui-kit";

import { Stack, useTheme } from "@mui/material";
import { Button } from "@sbm/ui-components";
import {
  DrawerTypes,
  DrawerVariantsEnum,
  IDocumentById,
  ModalVariants,
  ServiceNoteEntity,
  StatusForCorrespondenceEnum,
} from "@types";

import {
  AttachmentAndSending,
  CreateLetterStepper,
  DetailsStep,
} from "./components";
import {
  createRequestBody,
  getDefaultDataForEdit,
  isNextButtonActive,
} from "./helpers";

interface Props {
  data?: IDocumentById;
}

export const CreateServiceNoteContainer: React.FC<Props> = ({ data }) => {
  const isEditMode = Boolean(data);
  const defaultEditData = isEditMode ? getDefaultDataForEdit(data) : undefined;

  const { t } = useTranslation("correspondence");
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { userProfile } = useAppSelector((state) => state.auth);
  const { drawer } = useAppSelector((state) => state.global);
  const { letterCreationStep } = useAppSelector(
    (state) => state.correspondence
  );

  const [showPrompt, setShowPrompt] = useState(false);

  const {
    register,
    setValue,
    control,
    reset,
    getValues,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm<ServiceNoteEntity>({
    defaultValues: defaultEditData || {
      recipients: [{}],
    },
  });

  const fields = useWatch({ control });

  const isDraftStatus =
    data?.statusForCorrespondence === StatusForCorrespondenceEnum.draft;

  const userEmployeeOrganizationsAndPositions =
    getUserEmployeeOrganizationsAndPositions(userProfile?.personalData);

  const userEmployeeOrganizationsAndPositionsIds =
    userEmployeeOrganizationsAndPositions.map((i) => i.id);

  const userExternalSubstitutionOrganizationsAndPositions =
    getUserExternalSubstitutionOrganizationsAndPositions(
      userProfile?.personalData
    );

  const userExternalSubstitutionOrganizationsAndPositionsIds =
    userExternalSubstitutionOrganizationsAndPositions.map((i) => i.id);

  const isEmployeeAndExternalSubInSameOrganization = Boolean(
    fields.senderOrganizationId &&
      userEmployeeOrganizationsAndPositionsIds.includes(
        fields.senderOrganizationId
      ) &&
      userExternalSubstitutionOrganizationsAndPositionsIds.includes(
        fields.senderOrganizationId
      )
  );

  const isNextActive = isNextButtonActive(
    fields as ServiceNoteEntity,
    isEmployeeAndExternalSubInSameOrganization,
    getValues("textForTopicId"),
    getValues("topicForTheCorrespondence")
  );

  const onSuccess = () => {
    reset();
    dispatch(closeDrawer());
    dispatch(resetLetterCreationStep());
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const handleCancel = () => {
    if (Object.keys(dirtyFields).length > 0) {
      setShowPrompt(true);
      return;
    }

    reset();
    handleClose();
  };

  const handleConfirmTransition = () => {
    reset();
    dispatch(closeDrawer());
    dispatch(resetLetterCreationStep());
    handleClosePrompt();
  };

  const handleClose = () => {
    handleConfirmTransition();
  };

  const handleNextStep = () => {
    dispatch(setChangeLetterCreationStep(1));
  };

  const handleBackStep = () => {
    dispatch(setChangeLetterCreationStep(-1));
  };

  const { mutate: createServiceNote, isLoading } =
    useCreateServiceNoteMutation(onSuccess);

  const { mutate: editCorrespondence, isLoading: isLoadingEdit } =
    useEditCorrespondenceMutation(onSuccess);

  const handleDelete = () => {
    if (!data) return;

    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.removeCorrespondence,
        content: {
          data: {
            id: data.id,
            type: data.correspondenceType,
          },
        },
      })
    );
  };

  const onSubmit = handleSubmit(async (data) => {
    const id = data.id; // only for edit mode

    delete data["replyToOutgoingLetter"];
    delete data["initiatingDocumentRegistrationDate"];
    delete data["senderEmployeePosition"];
    delete data["senderStructuralUnitId"];
    delete data["selectConstructionComplexes"];
    delete data["id"];
    if (isEditMode) {
      delete data["senderOrganizationId"];
    }

    const updatedARecipients = data.recipients?.map((obj) => {
      if (obj.recipientExternalSubstitutionId) {
        obj.recipientStructuralUnitId = undefined;
        obj.recipientEmployeeId = undefined;
      }
      const { recipientEmployeePosition, recipientStructuralUnitId, ...rest } =
        obj;

      if (isEditMode) return rest;

      return obj;
    });

    const dataToSend: ServiceNoteEntity = {
      ...data,
      recipients: updatedARecipients,
    };

    if (data.creatorPosition) {
      dataToSend["isCreaterExternalSubstitution"] =
        data.creatorPosition === "external";

      delete dataToSend["creatorPosition"];
    }

    Object.keys(dataToSend).forEach((key) => {
      const index = key as keyof ServiceNoteEntity;
      if (!dataToSend[index] && dataToSend[index] !== false) {
        delete dataToSend[index];
      }
    });

    if (isEditMode) {
      delete dataToSend["senderExternalSubstitutionId"];
      editCorrespondence({ ...dataToSend, id });
    } else {
      const body = createRequestBody(dataToSend);

      createServiceNote(body);
    }
  });

  const renderFormContent = () => {
    if (isEditMode) {
      return (
        <DetailsStep
          control={control}
          setValue={setValue}
          getValues={getValues}
          register={register}
          errors={errors}
          isEditMode={isEditMode}
        />
      );
    }

    switch (letterCreationStep) {
      case 0:
        return (
          <DetailsStep
            control={control}
            setValue={setValue}
            getValues={getValues}
            register={register}
            errors={errors}
            isEditMode={isEditMode}
          />
        );
      case 1:
        return <AttachmentAndSending form={{ control, setValue, getValues }} />;
      default:
        return null;
    }
  };

  if (
    drawer === DrawerVariantsEnum.createServiceNote ||
    drawer === DrawerVariantsEnum.editCorrespondence
  ) {
    return (
      <>
        {showPrompt && (
          <TransitionPrompt
            open={showPrompt}
            onClose={handleClosePrompt}
            onConfirm={handleConfirmTransition}
          />
        )}

        <Drawer
          anchor="right"
          open={Boolean(drawer)}
          onClose={handleCancel}
          resizable={false}
          type={DrawerTypes.createLetterDrawer}
          title={t(isEditMode ? "edit" : "createLetterDrawer.title")}
          actions={
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Stack>
                {isEditMode ? (
                  isDraftStatus ? (
                    <Button
                      variant="text"
                      size="medium"
                      color="error"
                      onClick={handleDelete}
                      startIcon={
                        <Icon name="Trash2" color={theme.palette.error.main} />
                      }
                    >
                      {t("delete")}
                    </Button>
                  ) : null
                ) : !letterCreationStep ? null : (
                  <Button
                    variant="text"
                    size="medium"
                    color="secondary"
                    sx={{ fontSize: "15px" }}
                    onClick={handleBackStep}
                    startIcon={
                      <Icon
                        name="ArrowLeft"
                        color={theme.palette.secondary.main}
                      />
                    }
                  >
                    {t("back")}
                  </Button>
                )}
              </Stack>
              <Stack flexDirection="row" justifyContent="flex-end" gap={4}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={
                    (!isEditMode && !isNextActive) ||
                    (isEditMode && !Object.values(dirtyFields).length) ||
                    isLoading ||
                    isLoadingEdit
                  }
                  loading={isLoading || isLoadingEdit}
                  onClick={
                    isEditMode || letterCreationStep === 1
                      ? onSubmit
                      : handleNextStep
                  }
                >
                  {isEditMode
                    ? t("save")
                    : letterCreationStep === 1
                    ? t("create")
                    : t("next")}
                </Button>
              </Stack>
            </Stack>
          }
        >
          <Stack position="relative">
            <CreateLetterStepper isEditMode={isEditMode} />

            <form noValidate onSubmit={onSubmit} style={{ marginTop: "90px" }}>
              {renderFormContent()}
            </form>
          </Stack>
        </Drawer>
      </>
    );
  }

  return null;
};
