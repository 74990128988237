import { useAppSelector } from "app";

import { CorrespondenceTypeEnum, DrawerWithDataVariantsEnum } from "@types";

import { EditCorrAttachedFileForm } from "./EditCorrAttachedFileForm";

interface Props {
  correspondenceId: number | undefined;
  type: CorrespondenceTypeEnum;
}

export const EditCorrespondenceAttachedFile = ({
  correspondenceId,
  type,
}: Props) => {
  const { drawerWithData } = useAppSelector((state) => state.global);

  if (
    drawerWithData?.variant !==
    DrawerWithDataVariantsEnum.editCorrespondenceAttachment
  )
    return null;

  const fileId = drawerWithData?.content.data?.fileId;

  return (
    <EditCorrAttachedFileForm
      correspondenceId={correspondenceId}
      fileId={fileId}
      type={type}
    />
  );
};
