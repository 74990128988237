import React, { useCallback } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import {
  FileAttachedToCorrespondenceFileStatusEnum,
  FileAttachedToCorrespondenceTypeOfAttachmentEnum,
  IncomingDocumentEntity,
} from "@types";

import { FileItem } from "./FileItem";
import { Wrapper } from "./styles";

interface Props {
  control: Control<IncomingDocumentEntity>;
  setValue: UseFormSetValue<IncomingDocumentEntity>;
}

export const AttachedFile = ({ control, setValue }: Props) => {
  const {
    files,
    typeOfAttachment = [],
    fileStatusAttachment = [],
  } = useWatch({
    control,
  });

  const handleFileSelect = useCallback(
    (
      files: File | File[],
      typeOfAttachment?: FileAttachedToCorrespondenceTypeOfAttachmentEnum[],
      fileStatusAttachment?: FileAttachedToCorrespondenceFileStatusEnum[]
    ) => {
      setValue("files", files as File[], { shouldDirty: true });
      setValue("typeOfAttachment", typeOfAttachment, { shouldDirty: true });
      setValue("fileStatusAttachment", fileStatusAttachment, {
        shouldDirty: true,
      });
    },
    [setValue]
  );

  const removeFile = useCallback(
    (name?: string) => {
      if (!files || !Array.isArray(files)) {
        handleFileSelect([], undefined, undefined);
        return;
      }

      const indexOfRemoved = files.findIndex((i) => i.name === name) || 1;

      const filesToSet = files.filter((i) => i.name !== name);
      const typeOfAttachmentsToSet = typeOfAttachment?.splice(
        indexOfRemoved,
        1
      );
      const fileStatusAttachmentToSet = fileStatusAttachment?.splice(
        indexOfRemoved,
        1
      );

      handleFileSelect(
        filesToSet,
        typeOfAttachmentsToSet,
        fileStatusAttachmentToSet
      );
    },
    [fileStatusAttachment, files, handleFileSelect, typeOfAttachment]
  );

  return (
    <Wrapper>
      {files ? (
        Array.isArray(files) ? (
          files
            .sort((a, b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            })
            .map((item) => (
              <FileItem
                key={item.name + item.size}
                file={item}
                removeFile={() => removeFile(item.name)}
              />
            ))
        ) : (
          <FileItem file={files} removeFile={() => removeFile()} />
        )
      ) : null}
    </Wrapper>
  );
};
