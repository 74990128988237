import {
  IDocumentById,
  OutgoingDocumentEntity,
  TOutgoingDocumentRecipientEntity,
} from "@types";

export const createRequestBody = (body: OutgoingDocumentEntity) => {
  const formData = new FormData();

  const keys = Object.keys(body) as (keyof OutgoingDocumentEntity)[];
  keys.forEach((key) => {
    if (
      key !== "files" &&
      key !== "links" &&
      key !== "constructionComplexes" &&
      key !== "typeOfAttachment" &&
      key !== "fileStatusAttachment" &&
      key !== "recipients"
    ) {
      formData.append(key, body[key] as string);
    }
  });

  if (body.files?.length) {
    body.files?.forEach((file) => {
      formData.append("files", file);
    });
  }

  if (body.links?.length) {
    body.links?.forEach((link, index) => {
      formData.append(`links[${index}]`, link.id.toString());
    });
  }

  if (body.typeOfAttachment?.length) {
    body.typeOfAttachment?.forEach((typeOfAttachment, index) => {
      formData.append(
        `typeOfAttachment[${index}]`,
        typeOfAttachment.toString()
      );
    });
  }
  if (body.fileStatusAttachment?.length) {
    body.fileStatusAttachment?.forEach((fileStatusAttachment, index) => {
      formData.append(
        `fileStatusAttachment[${index}]`,
        fileStatusAttachment.toString()
      );
    });
  }

  if (body.constructionComplexes?.length) {
    body.constructionComplexes?.forEach((complex, index) => {
      formData.append(`constructionComplexes[${index}]`, complex.toString());
    });
  }

  if (body.recipients?.length) {
    body.recipients?.forEach((recipient, index) => {
      if (
        "legalEntity" in recipient &&
        typeof recipient.legalEntity !== "undefined"
      ) {
        formData.append(
          `recipients[${index}][legalEntity]`,
          recipient.legalEntity.toString()
        );
      }
      if (recipient.recipientEmployeeId) {
        formData.append(
          `recipients[${index}][recipientEmployeeId]`,
          recipient.recipientEmployeeId.toString()
        );
      }
      if (recipient.recipientOrganizationId) {
        formData.append(
          `recipients[${index}][recipientOrganizationId]`,
          recipient.recipientOrganizationId.toString()
        );
      }
      if (recipient.recipientPersonalDataId) {
        formData.append(
          `recipients[${index}][recipientPersonalDataId]`,
          recipient.recipientPersonalDataId.toString()
        );
      }
      if (recipient.externalOrganizationStaffId) {
        formData.append(
          `recipients[${index}][externalOrganizationStaffId]`,
          recipient.externalOrganizationStaffId.toString()
        );
      }
    });
  }

  return formData;
};

export const isNextButtonActive = (
  fields: OutgoingDocumentEntity,
  isEmployeeAndExternalSubInSameOrganization?: boolean,
  textForTopicId?: number,
  topicForTheCorrespondence?: string
) => {
  const REQUIRED_FIELDS: (keyof OutgoingDocumentEntity)[] = [
    "correspondenceSubtype",
    "senderEmployeeId",
    "senderOrganizationId",
    "deliveryMethod",
    "recipients",
  ];

  if (fields.replyToOutgoingLetter) {
    REQUIRED_FIELDS.push("initiatingCorrespondenceId");
  }

  if (isEmployeeAndExternalSubInSameOrganization) {
    REQUIRED_FIELDS.push("creatorPosition");
  }

  if (!textForTopicId && !topicForTheCorrespondence) {
    return false;
  }

  return REQUIRED_FIELDS.every((key) => {
    if (key === "recipients") {
      const isRecipientsFilled = fields[key].every((recipient) => {
        // @ts-ignore
        if (!recipient.legalEntity || recipient.legalEntity === "false") {
          return recipient.recipientPersonalDataId;
        } else {
          return (
            recipient.recipientOrganizationId &&
            (recipient.externalOrganizationStaffId ||
              recipient.recipientEmployeeId)
          );
        }
      });

      return isRecipientsFilled;
    }

    return fields[key] || fields[key] === false;
  });
};

export const getDefaultDataForEdit = (
  data?: IDocumentById
): OutgoingDocumentEntity | null => {
  if (!data) return null;

  return {
    id: data.id,
    correspondenceSubtype: data.correspondenceSubtype,
    initiatingCorrespondenceId: data.initiatingCorrespondenceId,
    initiatingDocumentOutTheSystem: data.initiatingDocumentOutTheSystem,
    initiatingDocumentRegistrationDate: data.initiatingCorrespondenceDate
      ? new Date(data.initiatingCorrespondenceDate)
      : undefined,
    expectedDateOfTheResponse: data.expectedDateOfTheResponse
      ? new Date(data.expectedDateOfTheResponse)
      : undefined,
    replyToOutgoingLetter: !!data.initiatingCorrespondenceId,
    confidentially: data.confidentially,
    selectConstructionComplexes: data.constructionComplexes.length > 0,
    constructionComplexes: data.constructionComplexes.map((i) => i.id),
    senderEmployeePosition: data.sender.position,
    senderEmployeeId: data.sender.employeeId,
    senderOrganizationId: data.sender.organizationId,
    textForTopicId: data.textForTopicId,
    controlTheResponse: data.controlTheResponse,
    deliveryMethod: data.deliveryMethod,
    topicForTheCorrespondence: data.topicForTheCorrespondence,
    contextForTheCorrespondence: data.contextForTheCorrespondence,
    recipients: (data.recipients.map((rec) => ({
      recipientEmployeeId: rec.employeeId || rec.externalSubstitutionId,
      legalEntity: rec.legalEntity,
      recipientPersonalDataId: rec.personalDataId,
      recipientOrganizationId: rec.organizationId,
      externalOrganizationStaffId: rec.externalOrganizationStaffId,
      recipientEmployeePosition: rec.position,
    })) || []) as TOutgoingDocumentRecipientEntity[],
  };
};
