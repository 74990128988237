import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  axiosService,
  getUserEmployeeOrganizationsAndPositions,
  getUserExternalSubstitutionOrganizationsAndPositions,
  useAppSelector,
} from "app";

import { Card, CardContent, Typography } from "@mui/material";
import { getPersonalDataName, getUniqueListBy } from "@sbm/fe-utils";
import { FormAutocomplete, FormSelect } from "@sbm/ui-components";
import { IOrganization, IPersonalData, StructuralUnitTypesEnum } from "@types";

import { DetailsStepProps } from "../DetailsStep";

const isViewMode = false;

export const DetailsStepSender: React.FC<
  Pick<
    DetailsStepProps,
    "control" | "setValue" | "register" | "errors" | "isEditMode"
  >
> = ({ control, setValue, isEditMode }) => {
  const { t } = useTranslation("correspondence");

  const { userProfile } = useAppSelector((state) => state.auth);

  const {
    senderOrganizationId,
    senderEmployeeId,
    senderExternalSubstitutionId,
    senderStructuralUnitId,
  } = useWatch({
    control,
  });

  const selectedEmployeeId = senderEmployeeId || senderExternalSubstitutionId;
  const isExternalSubstitution = Boolean(senderExternalSubstitutionId);

  const [inputOrganizationValue, setInputOrganizationValue] =
    React.useState("");
  const [inputStructuralUnitValue, setInputStructuralUnitValue] =
    React.useState("");
  const [inputEmployeeValue, setInputEmployeeValue] = React.useState("");

  const [organizationOptions, setOrganizationOptions] = React.useState<
    { title: string; id?: number; belongingToTheEvGroup: boolean }[]
  >([]);

  const [structuralUnitOptions, setStructuralUnitOptions] = React.useState<
    { title: string; id?: number }[]
  >([]);

  const [employeeOptions, setEmployeeOptions] = React.useState<
    {
      title: string;
      id?: number;
      position: "string";
      structuralUnits: {
        structuralUnitId: number;
        positionName: string;
        externalSubstitutionId: number;
      }[];
    }[]
  >([]);

  const userEmployeeOrganizationsAndPositions =
    getUserEmployeeOrganizationsAndPositions(userProfile!.personalData);
  const userExternalSubstitutionOrganizationsAndPositions =
    getUserExternalSubstitutionOrganizationsAndPositions(
      userProfile!.personalData
    );

  const uniqueEmployeeAndExternalSubList = getUniqueListBy(
    [
      ...userEmployeeOrganizationsAndPositions,
      ...userExternalSubstitutionOrganizationsAndPositions,
    ],
    "id"
  ).map((i) => i.id);

  const senderPositionOptions = useMemo(() => {
    if (employeeOptions?.length > 0) {
      let positions: string[] = [];

      employeeOptions.forEach((item) => {
        const positionNames = item.structuralUnits.map((i) => i.positionName);
        positions = [...positions, ...positionNames];
      });

      return (
        positions.map((position) => ({
          option: position,
          value: position,
        })) || []
      );
    }

    return [];
  }, [employeeOptions]);

  // Default values
  const getOrganizationDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !organizationOptions.length) return "";
      const organization = organizationOptions.find((i) => i.id === id);
      if (!organization) return "";
      return organization.title;
    },
    [organizationOptions]
  );

  const getStructuralUnitDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !structuralUnitOptions.length) return "";
      const structuralUnit = structuralUnitOptions.find((i) => i.id === id);
      if (!structuralUnit) return "";
      return structuralUnit.title;
    },
    [structuralUnitOptions]
  );

  const getEmployeeDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !employeeOptions.length) return "";

      const option = employeeOptions.find((i) => {
        if (isExternalSubstitution) {
          const externalSub = i.structuralUnits.find(
            (unit) => unit.externalSubstitutionId === selectedEmployeeId
          );

          return Boolean(externalSub);
        }

        return i.id === id;
      });

      if (!option) return "";

      return option.title;
    },
    [employeeOptions, isExternalSubstitution, selectedEmployeeId]
  );

  const fetchEmployees = React.useCallback(
    async (search: string) => {
      if (!senderOrganizationId || !senderStructuralUnitId) return [];

      const { data } = await axiosService({
        endpoint: `employees/organizations-structural-units/${senderOrganizationId}`,
        body: {
          search,
          "filter.structuralUnitId": senderStructuralUnitId,
          "filter.headOfStructuralUnit": true,
        },
      });

      return data
        ? (
            data.items as {
              personalData: IPersonalData;
              employeeId: number;
              structuralUnits: {
                structuralUnitId: number;
                positionName: string;
                externalSubstitutionId: number;
              }[];
            }[]
          )
            .map((i) => ({
              title: getPersonalDataName(i.personalData, true),
              id: i.employeeId,
              structuralUnits: i.structuralUnits,
            }))
            .filter((i) => Boolean(i.title))
        : [];
    },
    [senderOrganizationId, senderStructuralUnitId]
  );

  const fetchStructuralUnits = React.useCallback(
    async (search: string) => {
      if (!senderOrganizationId) return [];

      const { data } = await axiosService({
        endpoint: "structural-units",
        body: {
          search,
          "filter.organizationId": senderOrganizationId,
          "filter.includeOnlyParents": true,
        },
      });

      return data
        ? (
            data.items as {
              id: number;
              nameOfStructuralUnit: string;
              structuralUnitType: StructuralUnitTypesEnum;
            }[]
          )
            .map((i) => ({
              id: i.id,
              title: i.nameOfStructuralUnit,
              structuralUnitType: i.structuralUnitType,
            }))
            .filter((i) => Boolean(i.title))
        : [];
    },
    [senderOrganizationId]
  );

  const fetchOrganizations = React.useCallback(
    async (search: string) => {
      const { data } = await axiosService({
        endpoint: `organizations`,
        body: {
          search,
          "filter.organizationIds": uniqueEmployeeAndExternalSubList,
          "filter.belongingToTheEvGroup": true,
        },
      });

      const options = data
        ? (data.items as IOrganization[])
            .map((i) => ({
              title: i.briefTextOrganizationName || i.shortName,
              id: i.id,
              belongingToTheEvGroup: i.belongingToTheEvGroup,
            }))
            .filter((i) => Boolean(i.title))
        : [];

      if (senderOrganizationId && isViewMode) {
        const exists = options.findIndex((i) => i.id === senderOrganizationId);

        if (exists === -1) {
          const { data } = await axiosService({
            endpoint: `organizations/${senderOrganizationId}`,
          });

          const optionToAdd = {
            title: data.briefTextOrganizationName,
            id: data.id,
            belongingToTheEvGroup: data.belongingToTheEvGroup,
          };

          options.push(optionToAdd);
        }
      }
      return options || [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [senderOrganizationId, JSON.stringify(uniqueEmployeeAndExternalSubList)]
  );

  const handleEmployeeInputChange = (val: string, reason: string) => {
    setInputEmployeeValue(val);

    if (reason === "input") return;
    // Delete action
    if (reason === "clear") {
      setValue("senderEmployeeId", undefined);
      setValue("senderExternalSubstitutionId", undefined);
      setValue("senderEmployeePosition", undefined);
      return;
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = employeeOptions.find((i) => i.title === val);

      if (!option) return;

      const selectedStructuralUnit = option.structuralUnits?.find(
        (i) => i.structuralUnitId === senderStructuralUnitId
      );

      if (!selectedStructuralUnit) return;

      const isExternalSubstitution =
        selectedStructuralUnit.externalSubstitutionId;

      if (isExternalSubstitution) {
        setValue(
          "senderExternalSubstitutionId",
          selectedStructuralUnit.externalSubstitutionId
        );
      } else {
        setValue("senderEmployeeId", option.id);
      }

      setValue("senderEmployeePosition", selectedStructuralUnit.positionName);
    }
  };

  const handleOrganizationInputChange = (val: string, reason: string) => {
    setValue("topicForTheCorrespondence", undefined);
    setValue("textForTopicId", undefined);
    setInputOrganizationValue(val);

    if (reason === "input") return "";
    // Delete action
    if (reason === "clear") {
      setValue("senderOrganizationId", undefined);
      setValue("senderEmployeeId", undefined);
      setValue("senderStructuralUnitId", undefined);
      setValue("senderEmployeePosition", undefined);
      setValue("senderExternalSubstitutionId", undefined);
      return;
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = organizationOptions.find((i) => i.title === val);
      if (!option) return;

      setValue("senderOrganizationId", option.id);
    }
  };

  const handleStructuralUnitsInputChange = (val: string, reason: string) => {
    setInputStructuralUnitValue(val);

    if (reason === "input") return "";
    // Delete action
    if (reason === "clear") {
      setValue("senderEmployeeId", undefined);
      setValue("senderStructuralUnitId", undefined);
      setValue("senderExternalSubstitutionId", undefined);
      return;
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = structuralUnitOptions.find((i) => i.title === val);
      if (!option) return;

      setValue("senderStructuralUnitId", option.id);
    }
  };

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h9_semiBold" color="text.disabled" sx={{ mb: 2 }}>
          {t("createLetterDrawer.senders_area.title").toUpperCase()}
        </Typography>

        <FormAutocomplete
          required
          disabled={isEditMode}
          freeSolo={false}
          inputValue={inputOrganizationValue}
          label={t("createLetterDrawer.senders_area.organization")}
          defaultSelected={getOrganizationDefaultValue(senderOrganizationId)}
          fetchData={fetchOrganizations}
          onFetchDataSuccess={setOrganizationOptions}
          onInputChange={handleOrganizationInputChange}
        />

        <FormAutocomplete
          required
          disabled={!senderOrganizationId}
          freeSolo={false}
          inputValue={inputStructuralUnitValue}
          label={t("createServiceNote.senders_area.structural_unit")}
          defaultSelected={getStructuralUnitDefaultValue(
            senderStructuralUnitId
          )}
          fetchData={fetchStructuralUnits}
          onFetchDataSuccess={setStructuralUnitOptions}
          onInputChange={handleStructuralUnitsInputChange}
        />

        <FormAutocomplete
          required
          disabled={!senderOrganizationId || !senderStructuralUnitId}
          freeSolo={false}
          inputValue={inputEmployeeValue}
          label={t("createLetterDrawer.senders_area.fullName")}
          defaultSelected={getEmployeeDefaultValue(selectedEmployeeId)}
          fetchData={fetchEmployees}
          onFetchDataSuccess={setEmployeeOptions}
          onInputChange={handleEmployeeInputChange}
        />

        <FormSelect
          disabled
          label={t("createLetterDrawer.senders_area.position")}
          name="senderEmployeePosition"
          control={control}
          values={senderPositionOptions}
        />
      </CardContent>
    </Card>
  );
};
