import React from "react";
import { useTranslation } from "react-i18next";

import { fetchCorrespondence } from "api";

import { Grid } from "@mui/material";
import { FormAutocomplete, FormDatePicker, Input } from "@sbm/ui-components";
import { CorrespondenceTypeEnum, IncomingDocumentEntity } from "@types";

import { DetailsStepProps } from "../DetailsStep";

export const RegistrationAreaReplySection: React.FC<
  Pick<DetailsStepProps, "control" | "setValue" | "register" | "errors"> &
    Pick<IncomingDocumentEntity, "initiatingCorrespondenceId">
> = ({ register, setValue, errors, control, initiatingCorrespondenceId }) => {
  const { t } = useTranslation("correspondence");

  const [
    inputIncomingCorrespondenceValue,
    setInputIncomingCorrespondenceValue,
  ] = React.useState("");
  const [initiatingDocumentOptions, setInitiatingDocumentOptions] =
    React.useState<{ title: string; date: Date; id?: number }[]>([]);

  const getDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !initiatingDocumentOptions.length) return;

      const option = initiatingDocumentOptions.find((i) => i.id === id);
      if (!option) return;

      return option.title;
    },
    [initiatingDocumentOptions]
  );

  const fetchData = React.useCallback(async (search: string) => {
    const { items } = await fetchCorrespondence(
      search,
      CorrespondenceTypeEnum.serviceNote
    );

    return items
      .map(
        (i: {
          internalRegistrationNumber: string;
          registrationDate: Date;
          id: number;
        }) => ({
          title: i.internalRegistrationNumber,
          id: i.id,
          date: i.registrationDate,
        })
      )
      .filter((i: { title: string }) => Boolean(i.title));
  }, []);

  const handleIncomingCorrespondenceInputChange = (
    val: string,
    reason: string
  ) => {
    setInputIncomingCorrespondenceValue(val);

    if (reason === "input") return;

    // Delete action
    if (reason === "clear") {
      setValue("initiatingCorrespondenceId", undefined, { shouldDirty: true });
      setValue("initiatingDocumentRegistrationDate", undefined, {
        shouldDirty: true,
      });
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = initiatingDocumentOptions.find((i) => i.title === val);
      if (!option) return;

      setValue("initiatingCorrespondenceId", option.id, { shouldDirty: true });
      setValue("initiatingDocumentRegistrationDate", option.date, {
        shouldDirty: true,
      });
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <FormAutocomplete
          required
          freeSolo={false}
          inputValue={inputIncomingCorrespondenceValue}
          label={t("createServiceNote.registration_area.initiating_document")}
          defaultSelected={getDefaultValue(initiatingCorrespondenceId)}
          fetchData={fetchData}
          onFetchDataSuccess={setInitiatingDocumentOptions}
          onInputChange={handleIncomingCorrespondenceInputChange}
          error={!!errors.initiatingCorrespondenceId}
        />
      </Grid>

      <Grid item xs={6}>
        <FormDatePicker
          readOnly
          control={control}
          name="initiatingDocumentRegistrationDate"
          variant="outlined"
          size="medium"
          color="secondary"
          label={t("createLetterDrawer.registration_area.from")}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          label={t("createLetterDrawer.registration_area.note")}
          maxLength={300}
          {...register("initiatingDocumentOutTheSystem")}
        />
      </Grid>
    </Grid>
  );
};
