import React from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import { Icon } from "ui-kit";

import { Grid, Typography, useTheme } from "@mui/material";
import { IAttachLink, ServiceNoteEntity } from "@types";

import { Wrapper, LinksItemWrapper } from "./styles";

interface Props {
  control: Control<ServiceNoteEntity>;
  setValue: UseFormSetValue<ServiceNoteEntity>;
}

export const AttachedLink = ({ control, setValue }: Props) => {
  const { palette } = useTheme();

  const { links } = useWatch({ control });

  const onRemoveAttachedLink = (id?: number) => {
    if (!id) return;

    const updatedList = links?.filter((link) => link.id !== id);
    setValue("links", updatedList as IAttachLink[]);
  };

  return (
    <Wrapper>
      {links &&
        links.length > 0 &&
        links.map((link) => {
          return (
            <LinksItemWrapper key={link.id}>
              <Grid container>
                <Grid item xs={1} className="link_delete_icon">
                  <Icon name="Link" color={palette.secondary.main} />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    color="secondary.main"
                  >
                    {link.documentName}
                  </Typography>
                </Grid>
                <Grid item xs={1} className="link_delete_icon">
                  <Icon
                    name="X"
                    color={palette.primary.main}
                    onClick={() => onRemoveAttachedLink(link.id)}
                  />
                </Grid>
              </Grid>
            </LinksItemWrapper>
          );
        })}
    </Wrapper>
  );
};
