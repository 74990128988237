import { TFunction } from "i18next";

import { Chip, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { COLUMN_SIZE, getLocaleDateFormat, hexToRgba } from "@sbm/fe-utils";

export const getListOfApprovalsColumns = (
  t: TFunction,
  theme: Theme,
  lastIterationNumber: number
): GridColDef[] => {
  return [
    {
      field: "position",
      minWidth: COLUMN_SIZE * 4,
      headerName: t("approval.subject"),
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Stack>
            <Tooltip arrow placement="top" title={params.row.longName}>
              <Typography
                variant="body2"
                fontWeight={
                  lastIterationNumber === params.row.iterationNumber ? 600 : 400
                }
              >
                {params.row.longName}
              </Typography>
            </Tooltip>

            <Tooltip arrow placement="top" title={params.row.position}>
              <Typography
                variant="body2"
                fontWeight={
                  lastIterationNumber === params.row.iterationNumber ? 600 : 400
                }
                color="text.secondary"
              >
                {params.row.position}
              </Typography>
            </Tooltip>

            <Tooltip
              arrow
              placement="top"
              title={params.row.nameOfStructuralUnit}
            >
              <Typography
                variant="body2"
                fontWeight={
                  lastIterationNumber === params.row.iterationNumber ? 600 : 400
                }
                color="text.secondary"
              >
                {params.row.nameOfStructuralUnit}
              </Typography>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "date",
      minWidth: COLUMN_SIZE * 1.5,
      headerName: t("my.approvals.date"),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Tooltip arrow placement="top" title={params.row.date}>
            <Typography
              variant="body2"
              fontWeight={
                lastIterationNumber === params.row.iterationNumber ? 600 : 400
              }
            >
              {params.row.date}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "result",
      minWidth: COLUMN_SIZE * 4,
      headerName: t("decision"),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Tooltip arrow placement="top" title={params.row.result}>
            <Typography
              variant="body2"
              fontWeight={
                lastIterationNumber === params.row.iterationNumber ? 600 : 400
              }
            >
              {params.row.result}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "iteration",
      headerName: t("iteration"),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.iterationNumber}
            sx={{
              backgroundColor:
                lastIterationNumber === params.row.iterationNumber
                  ? theme.palette.primary.main
                  : hexToRgba(theme.palette.primary.main, 0.6),
              color: theme.palette.primary.contrastText,
              borderRadius: "50%",
              fontWeight: 600,
              width: 32,
              height: 32,
            }}
          />
        );
      },
    },
    {
      field: "comment",
      minWidth: COLUMN_SIZE * 3,
      flex: 1,
      headerName: t("comment"),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Tooltip arrow placement="top" title={params.row.comment}>
            <Typography
              variant="body2"
              fontWeight={
                lastIterationNumber === params.row.iterationNumber ? 600 : 400
              }
            >
              {params.row.comment}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];
};

export const getTimeView = (date: string | null) => {
  if (!date) return null;
  const formattedDate = getLocaleDateFormat(date, "hh:mm")?.split(",");
  const hasThreeItems = formattedDate?.length === 3;

  if (hasThreeItems) {
    return (
      <>
        <Typography variant="body2" fontWeight={600}>{`${
          formattedDate[0] || ""
        } ${formattedDate[1] || ""}`}</Typography>
        <Typography variant="body2" fontWeight={600}>{`${
          formattedDate[2] || ""
        }`}</Typography>
      </>
    );
  }
  return (
    <>
      <Typography variant="body2" fontWeight={600}>{`${
        formattedDate?.[0] || ""
      }`}</Typography>
      <Typography variant="body2" fontWeight={600}>{`${
        formattedDate?.[1] || ""
      }`}</Typography>
    </>
  );
};
