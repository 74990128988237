import React from "react";

import { FileFormatImage } from "ui-kit";

import { GridColDef } from "@mui/x-data-grid-pro";

import { CorrespondenceAttachedFileActions } from "./CorrespondenceAttachedFileActions";
import { CorrespondenceAttachedLinkColumnInTable } from "./CorrespondenceAttachedLinkColumnInTable";
import { CorrespondenceAttachedLinksActions } from "./CorrespondenceAttachedLinksActions";

const COLUMN_SIZE = 60;
export const TABLE_COLUMNS_FILES: GridColDef[] = [
  {
    field: "sequenceNumber",
    headerName: "attachmentFilesAndLinks.sequenceNumber",
    width: 0.5 * COLUMN_SIZE,
    sortable: false,
    filterable: false,
  },
  {
    field: "status",
    headerName: "attachmentFilesAndLinks.status",
    width: 2 * COLUMN_SIZE,
    sortable: false,
    filterable: false,
  },
  {
    field: "fileDestination",
    headerName: "attachmentFilesAndLinks.file_destination",
    width: 3 * COLUMN_SIZE,
    sortable: false,
    filterable: false,
  },
  {
    field: "fileFormat",
    headerName: "attachmentFilesAndLinks.file_format",
    sortable: false,
    width: 2 * COLUMN_SIZE,
    filterable: false,
    renderCell: (params) => {
      return <FileFormatImage format={params.value} />;
    },
  },
  {
    field: "fileName",
    headerName: "attachmentFilesAndLinks.file_name",
    width: 3 * COLUMN_SIZE,
    sortable: false,
    filterable: false,
  },
  {
    field: "uploadedDate",
    headerName: "attachmentFilesAndLinks.upload_date",
    minWidth: COLUMN_SIZE * 3,
    sortable: false,
    filterable: false,
  },
  {
    field: "uploadedBy",
    headerName: "attachmentFilesAndLinks.uploaded",
    width: 3 * COLUMN_SIZE,
    sortable: false,
    filterable: false,
  },
  {
    field: "additionalInformation",
    headerName: "attachmentFilesAndLinks.description",
    minWidth: COLUMN_SIZE * 2,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: "actions",
    headerName: "",
    width: COLUMN_SIZE * 0.5,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <CorrespondenceAttachedFileActions
          fileNameForSystem={params.row.fileNameForSystem}
          fileName={params.row.fileName}
          uploaderId={params.row.uploaderId}
          additionalInformation={params.row.additionalInformation}
          typeOfAttachment={params.row.typeOfAttachment}
          fileFormat={params.row.fileFormat}
          fileId={params.row.fileId}
          fileStatus={params.row.fileStatus}
        />
      );
    },
  },
];

export const TABLE_COLUMNS_LINKS: GridColDef[] = [
  {
    field: "sequenceNumber",
    headerName: "attachmentFilesAndLinks.sequenceNumber",
    width: 0.5 * COLUMN_SIZE,
    sortable: false,
    filterable: false,
  },
  {
    field: "assignment",
    headerName: "attachmentFilesAndLinks.assignment",
    width: 6 * COLUMN_SIZE,
    sortable: false,
    filterable: false,
  },
  {
    field: "link",
    headerName: "attachmentFilesAndLinks.link",
    width: 6 * COLUMN_SIZE,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return <CorrespondenceAttachedLinkColumnInTable data={params.row} />;
    },
  },
  {
    field: "linkAddedBy",
    headerName: "attachmentFilesAndLinks.link_added_by",
    width: 3 * COLUMN_SIZE,
    sortable: false,
    filterable: false,
  },
  {
    field: "createdAt",
    headerName: "attachmentFilesAndLinks.date",
    minWidth: COLUMN_SIZE * 3,
    sortable: false,
    filterable: false,
  },
  {
    field: "note",
    headerName: "attachmentFilesAndLinks.note",
    minWidth: COLUMN_SIZE * 2,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: "actions",
    headerName: "",
    width: COLUMN_SIZE * 0.5,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return <CorrespondenceAttachedLinksActions />;
    },
  },
];
