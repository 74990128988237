import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { FormDatePicker, FormSelect } from "@sbm/ui-components";
import { CorrespondenceSubtypeEnum } from "@types";

import { DetailsStepProps } from "../DetailsStep";
import { RegistrationAreaConstructionComplexes } from "./RegistrationAreaConstructionComplexes";
import { RegistrationAreaReplySection } from "./RegistrationAreaReplySection";

export const RegistrationArea: React.FC<
  Pick<DetailsStepProps, "control" | "setValue" | "register" | "errors">
> = (props) => {
  const { control, setValue, register, errors } = props;

  const { t } = useTranslation("correspondence");

  const {
    replyToOutgoingLetter,
    selectConstructionComplexes,
    confidentially,
    initiatingCorrespondenceId,
    controlTheResponse,
  } = useWatch({
    control,
  });

  const documentTypeOptions = Object.values(CorrespondenceSubtypeEnum).map(
    (subtype) => ({
      value: subtype,
      option: t(`correspondence_subtype.${subtype}`),
    })
  );

  useEffect(() => {
    if (!replyToOutgoingLetter) {
      // remove all fields from reply to outgoing message section if switcher is off
      setValue("initiatingCorrespondenceId", undefined);
      setValue("initiatingDocumentOutTheSystem", undefined);
      setValue("initiatingDocumentRegistrationDate", undefined, {
        shouldDirty: true,
      });
    }
  }, [replyToOutgoingLetter, setValue]);

  useEffect(() => {
    if (!selectConstructionComplexes) {
      // remove construction complexes if switcher is off
      setValue("constructionComplexes", []);
    }
  }, [selectConstructionComplexes, setValue]);

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h9_semiBold" color="text.disabled" sx={{ mb: 2 }}>
          {t("createLetterDrawer.registration_area.title").toUpperCase()}
        </Typography>

        <FormSelect
          required
          label={t("createLetterDrawer.registration_area.document_type")}
          name="correspondenceSubtype"
          control={control}
          values={documentTypeOptions}
          error={!!errors.correspondenceSubtype}
        />

        <FormControlLabel
          label={t("createOutgoingDocument.registration_area.reply_switcher")}
          control={
            <Switch
              color="secondary"
              defaultChecked={replyToOutgoingLetter}
              {...register("replyToOutgoingLetter")}
            />
          }
        />

        {replyToOutgoingLetter && (
          <RegistrationAreaReplySection
            {...props}
            initiatingCorrespondenceId={initiatingCorrespondenceId}
          />
        )}

        <FormControlLabel
          label={t(
            "createLetterDrawer.registration_area.confidential_switcher"
          )}
          control={
            <Switch
              color="secondary"
              defaultChecked={confidentially}
              {...register("confidentially")}
            />
          }
        />

        <FormControlLabel
          label={t(
            "createLetterDrawer.registration_area.construction_switcher"
          )}
          control={
            <Switch
              color="secondary"
              defaultChecked={selectConstructionComplexes}
              {...register("selectConstructionComplexes")}
            />
          }
        />

        {selectConstructionComplexes && (
          <RegistrationAreaConstructionComplexes
            setValue={setValue}
            control={control}
          />
        )}

        <FormControlLabel
          label={t(
            "createOutgoingDocument.registration_area.control_response_label"
          )}
          control={
            <Switch
              color="secondary"
              defaultChecked={controlTheResponse}
              {...register("controlTheResponse")}
            />
          }
        />

        {controlTheResponse && (
          <FormDatePicker
            control={control}
            name="expectedDateOfTheResponse"
            variant="outlined"
            size="medium"
            color="secondary"
            label={t(
              "createOutgoingDocument.registration_area.expected_response_date_label"
            )}
          />
        )}
      </CardContent>
    </Card>
  );
};
