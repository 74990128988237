import React from "react";
import { useNavigate } from "react-router-dom";

import { Icon } from "ui-kit";

import { Typography, Stack, useTheme } from "@mui/material";
import { ICorrespondenceAttachedLinkColumnInTable } from "@types";

interface Props {
  data: ICorrespondenceAttachedLinkColumnInTable;
}

export const CorrespondenceAttachedLinkColumnInTable = ({ data }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    contractId,
    typeOfTheDocument,
    supplementaryAgreementId,
    taskId,
    correspondenceId,
    externalLinkUrl,
  } = data.linkedEntities;

  const linkUrl = React.useMemo(() => {
    switch (typeOfTheDocument) {
      case "contract":
        return `/documents/contracts/${contractId}`;
      case "supplementary_agreement":
        return `/documents/contracts/${contractId}/sa/${supplementaryAgreementId}`;
      case "task":
        return `/tasks/${taskId}`;
      case "incoming_document":
      case "outgoing_document":
      case "service_note":
        return `/documents/correspondence/${correspondenceId}`;
      default:
        return "";
    }
  }, [
    contractId,
    supplementaryAgreementId,
    taskId,
    typeOfTheDocument,
    correspondenceId,
  ]);

  const handleNavigate = () => {
    if (externalLinkUrl) {
      window.open(externalLinkUrl, "_blank");
      return;
    }
    navigate(linkUrl);
  };
  return (
    <Stack
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={1}
      sx={{ cursor: "pointer" }}
    >
      <Icon
        name="Link"
        size={16}
        strokeWidth={3}
        color={theme.palette.secondary.main}
      />

      <Typography
        variant="body2"
        onClick={handleNavigate}
        fontWeight={600}
        color="secondary.main"
        sx={{
          textDecoration: "underline",
          textUnderlinePosition: "under",
        }}
      >
        {data.link}
      </Typography>
    </Stack>
  );
};
