import React from "react";
import { useTranslation } from "react-i18next";

import { Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { MimeTypesEnum } from "@types";

interface Props {
  previewUrl: string;
  fileFormat: MimeTypesEnum;
  loading: boolean;
}

export const PreviewFile = ({ previewUrl, fileFormat, loading }: Props) => {
  const { t } = useTranslation();

  const handleOpenFile = () => {
    if (previewUrl) {
      window.open(previewUrl, "_blank");
    }
  };

  return (
    <Stack
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {loading || !previewUrl ? (
        <CircularProgress />
      ) : previewUrl ? (
        fileFormat === MimeTypesEnum.jpg ||
        fileFormat === MimeTypesEnum.bmp ||
        fileFormat === MimeTypesEnum.jpeg ||
        fileFormat === MimeTypesEnum.png ? (
          <Stack
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderColor="text.disabled"
            border={1}
            padding={3}
            width="300"
          >
            <img
              src={previewUrl}
              alt="Preview"
              onClick={handleOpenFile}
              style={{ width: "300px", maxHeight: "100%", cursor: "pointer" }}
            />
          </Stack>
        ) : null
      ) : (
        <Typography>{t("noPreviewAvailable")}</Typography>
      )}
    </Stack>
  );
};
