import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetDocumentById } from "api";
import { BasicTabs, DotsLoader } from "ui-kit";

import { Stack } from "@mui/material";
import { getAPIErrorMessage } from "@sbm/fe-utils";
import {
  CorrespondenceTypeEnum,
  DrawerVariantsEnum,
  StatusForCorrespondenceEnum,
  TypeOfObjectInternalApprovalEnum,
} from "@types";

import { useAppSelector, useTabNavigation } from "../../../hooks";
import { AgreementContainer } from "../../agreements";
import { DocumentCard, DocumentDetails } from "../components";
import { CorrespondenceAttachmentsAndFiles } from "../components/CorrespondenceAttachmentsAndFiles";
import { CorrespondenceEventsContainer } from "../CorrespondenceEventsContainer";
import { CreateIncomingDocument } from "../CreateIncomingDocument";
import { CreateOutgoingDocument } from "../CreateOutgoingDocument";
import { CreateServiceNoteContainer } from "../CreateServiceNoteContainer";

const STATUS_TO_SHOW_AGREEMENT = [
  StatusForCorrespondenceEnum.draft,
  StatusForCorrespondenceEnum.atSigning,
  StatusForCorrespondenceEnum.toBeAgreed,
];

export const ViewDocumentContainer = () => {
  const { id } = useParams();
  const { tab, handleChange } = useTabNavigation();
  const { t } = useTranslation("correspondence");

  const { userProfile } = useAppSelector((state) => state.auth);
  const { drawer } = useAppSelector((state) => state.global);

  const { data, isLoading, error } = useGetDocumentById(id, {
    enabled: Boolean(id),
  });

  if (error) {
    const errorMSG = getAPIErrorMessage(error);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }

  const isAuthor =
    userProfile?.personalDataId === data?.registeredByPersonalDataId;

  const isIncoming =
    data?.correspondenceType === CorrespondenceTypeEnum.incomingDocument;
  const isOutgoing =
    data?.correspondenceType === CorrespondenceTypeEnum.outgoingDocument;
  const isServiceNote =
    data?.correspondenceType === CorrespondenceTypeEnum.serviceNote;

  const renderEditCorrespondence = (drawer: DrawerVariantsEnum | undefined) => {
    // ToDo: remove after not needed
    if (
      /*!isAuthor ||*/ !data ||
      drawer !== DrawerVariantsEnum.editCorrespondence
    )
      return null;

    switch (true) {
      case isIncoming:
        return <CreateIncomingDocument data={data} />;
      case isOutgoing:
        return <CreateOutgoingDocument data={data} />;
      case isServiceNote:
        return <CreateServiceNoteContainer data={data} />;

      default:
        return null;
    }
  };

  const memoizedTabs = React.useMemo(() => {
    if (!data) return [];

    const showAgreementTab = Boolean(
      STATUS_TO_SHOW_AGREEMENT.includes(data.statusForCorrespondence) &&
        data?.hasObjectInternalApprovalProcess &&
        (isOutgoing || isServiceNote)
    );

    const tabs = [
      {
        label: t("viewDocument.document_details"),
        component: (
          <DocumentDetails data={data} showAgreementTab={showAgreementTab} />
        ),
      },
      {
        label: data?.attachmentsCount
          ? `${t("viewDocument.attachments_&_files")} (${
              data.attachmentsCount
            })`
          : t("viewDocument.attachments_&_files"),
        component: (
          <CorrespondenceAttachmentsAndFiles
            correspondenceId={data.id}
            type={data.correspondenceType!}
            isAuthor={isAuthor}
          />
        ),
      },
      {
        label: t("viewDocument.event_log"),
        component: (
          <CorrespondenceEventsContainer
            registrationDate={data.registrationDate}
            documentNumber={data.internalRegistrationNumber}
          />
        ),
      },
    ];

    if (showAgreementTab) {
      tabs.push({
        label: t("viewDocument.approval"),
        component: (
          <AgreementContainer
            data={data}
            type={
              data.correspondenceType as unknown as TypeOfObjectInternalApprovalEnum
            }
          />
        ),
      });
    }

    return tabs;
  }, [data, isAuthor, isOutgoing, isServiceNote, t]);

  if (isLoading || !data) return <DotsLoader />;

  return (
    <Stack gap={6}>
      <DocumentCard data={data} />

      <BasicTabs
        sticky={false}
        tabIndex={tab}
        onChange={handleChange}
        tabs={memoizedTabs}
      />

      {renderEditCorrespondence(drawer)}
    </Stack>
  );
};

export default ViewDocumentContainer;
