import { createSlice } from "@reduxjs/toolkit";
import { MessageAttachmentsType, MessengerModeType } from "@types";

interface IMessage {
  currentMessageId: any;
  tab: MessageAttachmentsType.FILES | MessageAttachmentsType.LINKS;
  messengerMode: MessengerModeType;
  activeUsers: string[];
}

export const initialStateMessages: IMessage = {
  currentMessageId: null,
  tab: MessageAttachmentsType.FILES,
  messengerMode: MessengerModeType.CHAT,
  activeUsers: [],
};

export const messageSlice = createSlice({
  name: "message",
  initialState: initialStateMessages,
  reducers: {
    setShowMessageDetails: (state, action) => {
      state.currentMessageId = action.payload;
    },
    setActiveUsers: (state, action) => {
      state.activeUsers = action.payload;
    },
    setChangeTab: (state, action) => {
      state.tab = action.payload;
    },
    setChangeMessengerMode: (state, action) => {
      state.messengerMode = action.payload;
    },
  },
});

export const {
  setShowMessageDetails,
  setChangeTab,
  setChangeMessengerMode,
  setActiveUsers,
} = messageSlice.actions;

export default messageSlice.reducer;
