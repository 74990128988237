import { FC, useState, MouseEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";

import { Icon } from "ui-kit";

import {
  ClickAwayListener,
  Fade,
  ListItemButton,
  Paper,
  Popper,
  Typography,
  useTheme,
} from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { TIconNames } from "@types";

import { TSidebarItem } from "../../constants";
import { PopperContent } from "./PopperContent";

export interface SidebarItemsProps {
  openCollapse: string;
  onClick: (route: string, collapse?: boolean | undefined) => void;
  collapse?: TSidebarItem[];
  open: boolean;
  icon: TIconNames;
  name: string;
  route: string;
}

export const SidebarItems: FC<SidebarItemsProps> = ({
  openCollapse,
  onClick,
  collapse,
  open,
  name,
  route,
  icon,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const isCollapseOpened = openCollapse === route;

  const isActive =
    matchPath(route, pathname) || (route !== "/" && pathname.includes(route));

  const toggledColor = isActive
    ? theme.palette.primary.main
    : theme.palette.primary.contrastText;

  const handleOpenPopper = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper((prev) => !prev);
  };

  const handleClosePopper = useCallback(() => {
    setAnchorEl(null);
    setOpenPopper((prev) => !prev);
  }, []);

  const togglePopper = (event: MouseEvent<HTMLDivElement>) => {
    openPopper ? handleClosePopper() : handleOpenPopper(event);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!open && collapse) {
      togglePopper(event);
      return;
    }

    onClick(route, Boolean(collapse));
  };

  return (
    <ListItemButton
      onClick={handleClick}
      sx={{
        minHeight: 40,
        justifyContent: open ? "initial" : "center",
        p: 2,
        gap: 4,
        borderRadius: 2,
        backgroundColor: isActive
          ? theme.palette.primary.contrastText
          : // : theme.palette.primary.main,
            "",
        "&:hover": {
          backgroundColor: isActive
            ? theme.palette.primary.contrastText
            : hexToRgba(theme.palette.primary.contrastText, 0.04),
        },
      }}
    >
      {openPopper && (
        <ClickAwayListener
          disableReactTree={false}
          onClickAway={handleClosePopper}
        >
          <Popper
            open={openPopper}
            anchorEl={anchorEl}
            placement="right-start"
            transition
            sx={{ left: "16px !important" }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ borderRadius: 4, py: 4, px: 0, minWidth: 250 }}>
                  <PopperContent
                    collapse={collapse}
                    name={name}
                    route={route}
                    onClick={onClick}
                  />
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}

      <Icon name={icon} color={toggledColor} />
      {open && (
        <Fade in timeout={500}>
          <Typography
            variant="subtitle2"
            fontWeight={600}
            sx={{ flex: 1 }}
            color={isActive ? "primary.main" : "primary.contrastText"}
          >
            {t(name)}
          </Typography>
        </Fade>
      )}

      {collapse &&
        collapse.length > 0 &&
        open &&
        (!isCollapseOpened ? (
          <Icon name="ChevronDown" color={toggledColor} />
        ) : (
          <Icon name="ChevronUp" color={theme.palette.primary.main} />
        ))}
    </ListItemButton>
  );
};
