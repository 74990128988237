import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useGetCurrentUserApprovals } from "api";
import { UI_CONFIG } from "app";
import { Drawer, Table } from "ui-kit";

import {
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { capitalize, getPersonalDataName } from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";
import { EnumApproverDocumentStatusType } from "@types";

import { getListOfApprovalsColumns, getTimeView } from "./helpers";
import { TableWrapper } from "./styles";

interface Props {
  objectInternalApprovalProcessId: number;
  onClose?: () => void;
}

export const ListOfApprovals: React.FC<Props> = ({
  objectInternalApprovalProcessId,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement | null>(null);

  const { data, isLoading, isError } = useGetCurrentUserApprovals(
    objectInternalApprovalProcessId
  );

  const approvals = data?.comments;

  const lastIteration = approvals?.reduce((acc, loc) => {
    return loc.iterationNumber > acc.iterationNumber ? loc : acc;
  }, approvals[0]);

  const lastIterationNumber = lastIteration?.iterationNumber || 1;

  const rows = useMemo(() => {
    if (!approvals) return [];

    return approvals.map((item, index) => ({
      ...item,
      id: item.position + index + 1,
      date: getTimeView(item.date),
      result: t(item.result),
      longName: getPersonalDataName(item.personalData),
      className: lastIterationNumber !== item.iterationNumber ? "disabled" : "",
    }));
  }, [approvals, t, lastIterationNumber]);

  const columns: GridColDef[] = useMemo(() => {
    const approvalColumns = getListOfApprovalsColumns(
      t,
      theme,
      lastIterationNumber
    );

    return approvalColumns;
  }, [t, theme, lastIterationNumber]);

  const getRowClassName = (params: GridRowParams) => params.row.className;

  if (isError || isLoading) return null;

  return (
    <Drawer
      open
      anchor="right"
      onClose={onClose}
      resizable={false}
      title={t("agreement.sheet")}
      width={UI_CONFIG.rightDrawerFullWidth}
      actions={
        <Stack alignItems="end">
          <Button
            size="large"
            color="secondary"
            variant="contained"
            onClick={onClose}
            sx={{ width: "fit-content" }}
          >
            {capitalize(t("cancel"))}
          </Button>
        </Stack>
      }
    >
      <Card>
        <CardContent>
          <Grid container>
            <Grid xs={12} xl={6}>
              <Typography variant="body4" color="text.secondary">
                {t("document")}
              </Typography>

              <Tooltip
                arrow
                placement="top"
                title={data?.longNameOfTheDocument ?? ""}
              >
                <Typography
                  variant="body2"
                  fontWeight={600}
                  className="ellipsis"
                >
                  {data?.longNameOfTheDocument ?? "-"}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid xs={12} xl={2}>
              <Typography variant="body4" color="text.secondary">
                {t("approval.route")}
              </Typography>
              <Typography variant="body2" fontWeight={600}>
                {data?.numberOfApprovalRoute
                  ? t("typical_route_number", {
                      number: data.numberOfApprovalRoute,
                    })
                  : data?.typeOfApprovalRoute
                  ? t(`types_approval_route.${data.typeOfApprovalRoute}`)
                  : "-"}
              </Typography>
            </Grid>
            <Grid xs={12} xl={2}>
              <Typography variant="body4" color="text.secondary">
                {t("approval_start")}
              </Typography>
              <Typography variant="body2" fontWeight={600}>
                {getTimeView(data?.dateApprovalBeginning) || "-"}
              </Typography>
            </Grid>
            <Grid xs={12} xl={2}>
              <Typography variant="body4" color="text.secondary">
                {t("approval_end")}
              </Typography>
              <Typography variant="body2" fontWeight={600}>
                {getTimeView(data?.factDateApprovalApprovalEnding) || "-"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <TableWrapper ref={ref}>
        <Table
          rows={rows}
          columns={columns}
          loading={isLoading}
          hasToolbar
          hasPagination={false}
          rowHeight={70}
          getRowClassName={getRowClassName}
        />

        {data?.statusApprovalProcessInternalObject ===
          EnumApproverDocumentStatusType.agreement_completed && (
          <Stack p={4}>
            <Typography variant="h8" fontWeight={700}>
              {t("agreement_completed")}
            </Typography>
          </Stack>
        )}
      </TableWrapper>
    </Drawer>
  );
};
