import { TFunction } from "i18next";

import { formatDateToQuery } from "@sbm/fe-utils";
import { IFiltersMessageCorrespondents } from "@types";

export const getColumnNameFromArray = (
  value: string | string[] | undefined,
  t: TFunction<"translation", undefined, "translation">
): string => {
  if (typeof value === "string") return t(value);

  if (!value?.length) return "";

  let result = "";

  value.forEach((str, index) => {
    const isLast = index === value.length - 1;
    const stringToAdd = `${t(str)}${isLast ? "" : " "}`;

    result = result.concat(stringToAdd);
  });

  return result;
};

const findIdByName = (name: string, value: string) => {
  let result: number | string | object | undefined;
  switch (name) {
    case "createdAt":
      result = formatDateToQuery(value);
      break;
  }

  if (!result) return;
  return result;
};

export const getFilterValues = (
  selectedFilters: string[],
  t: TFunction<"correspondence", undefined, "correspondence">,
  correspondents?: IFiltersMessageCorrespondents[]
) => {
  if (!selectedFilters?.length) return [];

  return selectedFilters.map((filter) => {
    const parts = filter.split("=");
    const filterKey = parts[0];
    const filterValue = parts[1];
    const values = filterValue.split(",");

    let query = `${filterKey}=`;

    values.forEach((value, index) => {
      const isLast = index === values.length - 1;

      const idOfFilter = findIdByName(filterKey, value);

      if (idOfFilter) {
        query = query + idOfFilter;
        if (!isLast) query = query + ",";
      }
    });

    return query;
  });
};
