import React from "react";
import { useTranslation } from "react-i18next";

import { Stack, Tooltip, Typography } from "@mui/material";
import { IAgreementDuration } from "@types";

import { ApprovalSubjectActionsMenu } from "./ApprovalSubjectActionsMenu";

interface Props {
  name: string;
  position: string;
  approverType: string;
  amountOfDays: number;
  onUpdateDuration: (duration: IAgreementDuration) => void;
  onDelete: ({ id, queueNumber }: { id: number; queueNumber: number }) => void;
  onMoveLeft: ({
    id,
    queueNumber,
  }: {
    id: number;
    queueNumber: number;
  }) => void;
  onMoveRight: ({
    id,
    queueNumber,
  }: {
    id: number;
    queueNumber: number;
  }) => void;
  changeDuration?: () => number;
  allowedDelete: boolean;
  allowedLeft: boolean;
  allowedRight: boolean;
  queueNumber: number;
  id: number;
}

export const ApprovalSubject = ({
  name,
  position,
  approverType,
  amountOfDays,
  onUpdateDuration,
  onDelete,
  allowedDelete,
  onMoveLeft,
  onMoveRight,
  allowedLeft,
  allowedRight,
  queueNumber,
  id,
}: Props) => {
  const { t } = useTranslation("correspondence");

  return (
    <Stack className="ellipsis" sx={{ width: "100%" }}>
      <Stack direction="row" justifyContent="space-between">
        <Tooltip arrow placement="top" title={name}>
          <Typography variant="body1" fontWeight={600} className="ellipsis">
            {name}
          </Typography>
        </Tooltip>
        <ApprovalSubjectActionsMenu
          amountOfDays={amountOfDays}
          queueNumber={queueNumber}
          onUpdateDuration={onUpdateDuration}
          onDelete={onDelete}
          onMoveLeft={onMoveLeft}
          onMoveRight={onMoveRight}
          allowedDelete={allowedDelete}
          allowedLeft={allowedLeft}
          allowedRight={allowedRight}
          id={id}
        />
      </Stack>

      <Tooltip arrow placement="top" title={position}>
        <Typography className="ellipsis" variant="body2" color="text.secondary">
          {position}
        </Typography>
      </Tooltip>

      <Tooltip arrow placement="top" title={approverType}>
        <Typography
          component="p"
          variant="body4"
          fontWeight={700}
          color="text.disabled"
          className="ellipsis"
        >
          {approverType}
        </Typography>
      </Tooltip>

      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="body4" fontWeight={700} color="text.disabled">
          {amountOfDays}
        </Typography>
        <Typography variant="body4" fontWeight={700} color="text.disabled">
          {t("day")}
        </Typography>
      </Stack>
    </Stack>
  );
};
