import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useDeleteCorrespondenceMutation } from "api";
import { Modal } from "ui-kit";

import { Button } from "@mui/material";
import { CorrespondenceTypeEnum } from "@types";

import { useAppDispatch } from "../../../../hooks";
import { closeDrawer } from "../../../../store";

export const RemoveCorrespondencePopup = ({
  open,
  onClose,
  data: { data },
}: {
  open: boolean;
  onClose: () => void;
  data: { data: { id: number; type: CorrespondenceTypeEnum } };
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("correspondence");

  const getTabToShowQueryParam = () => {
    switch (data?.type) {
      case CorrespondenceTypeEnum.outgoingDocument:
        return 1;
      case CorrespondenceTypeEnum.serviceNote:
        return 2;
      default:
        return 0;
    }
  };

  const onSuccess = () => {
    onClose();
    navigate(`/documents/correspondence/?tab=${getTabToShowQueryParam()}`);
    dispatch(closeDrawer());
  };

  const { mutate: deleteServiceNote } =
    useDeleteCorrespondenceMutation(onSuccess);

  const content = {
    title: "",
    body: t("remove_correspondence_popup.title"),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("remove_correspondence_popup.cancel")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => {
          deleteServiceNote(data.id);
        }}
      >
        {t("remove_correspondence_popup.confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
