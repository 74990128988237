import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DatePicker } from "ui-kit";

import {
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { FormSelect, Input } from "@sbm/ui-components";
import { CorrespondenceSubtypeEnum } from "@types";

import { DetailsStepProps } from "../DetailsStep";
import { RegistrationAreaConstructionComplexes } from "./RegistrationAreaConstructionComplexes";
import { RegistrationAreaReplySection } from "./RegistrationAreaReplySection";

export const RegistrationArea: React.FC<
  Pick<DetailsStepProps, "control" | "setValue" | "register" | "errors">
> = (props) => {
  const { control, setValue, register, errors } = props;

  const { t } = useTranslation("correspondence");

  const {
    replyToOutgoingLetter,
    selectConstructionComplexes,
    confidentially,
    dateOfRegistration,
    initiatingCorrespondenceId,
  } = useWatch({
    control,
  });

  const documentTypeOptions = Object.values(CorrespondenceSubtypeEnum).map(
    (subtype) => ({
      value: subtype,
      option: t(`correspondence_subtype.${subtype}`),
    })
  );

  const handleDateChange = (date: Date) => {
    setValue("dateOfRegistration", date, { shouldDirty: true });
  };

  useEffect(() => {
    if (!replyToOutgoingLetter) {
      // remove all fields from reply to outgoing message section if switcher is off
      setValue("initiatingCorrespondenceId", undefined);
      setValue("initiatingDocumentOutTheSystem", undefined);
      setValue("initiatingDocumentRegistrationDate", undefined, {
        shouldDirty: true,
      });
    }
  }, [replyToOutgoingLetter, setValue]);

  useEffect(() => {
    if (!selectConstructionComplexes) {
      // remove construction complexes if switcher is off
      setValue("constructionComplexes", []);
    }
  }, [selectConstructionComplexes, setValue]);

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h9_semiBold" color="text.disabled" sx={{ mb: 2 }}>
          {t("createLetterDrawer.registration_area.title").toUpperCase()}
        </Typography>

        <FormSelect
          required
          label={t("createLetterDrawer.registration_area.document_type")}
          name="correspondenceSubtype"
          control={control}
          values={documentTypeOptions}
          error={!!errors.correspondenceSubtype}
        />

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Input
              required
              label={t(
                "createLetterDrawer.registration_area.number_correspondent"
              )}
              maxLength={200}
              error={!!errors.regNumberForCounterparty}
              {...register("regNumberForCounterparty")}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              required
              label={t("createLetterDrawer.registration_area.from")}
              variant="outlined"
              color="secondary"
              size="medium"
              onDateChange={handleDateChange}
              value={
                dateOfRegistration ? new Date(dateOfRegistration) : undefined
              }
            />
          </Grid>
        </Grid>

        <FormControlLabel
          label={t("createLetterDrawer.registration_area.reply_switcher")}
          control={
            <Switch
              color="secondary"
              defaultChecked={replyToOutgoingLetter}
              {...register("replyToOutgoingLetter")}
            />
          }
        />

        {replyToOutgoingLetter && (
          <RegistrationAreaReplySection
            {...props}
            initiatingCorrespondenceId={initiatingCorrespondenceId}
          />
        )}

        <FormControlLabel
          label={t(
            "createLetterDrawer.registration_area.confidential_switcher"
          )}
          control={
            <Switch
              color="secondary"
              defaultChecked={confidentially}
              {...register("confidentially")}
            />
          }
        />

        <FormControlLabel
          label={t(
            "createLetterDrawer.registration_area.construction_switcher"
          )}
          control={
            <Switch
              color="secondary"
              defaultChecked={selectConstructionComplexes}
              {...register("selectConstructionComplexes")}
            />
          }
        />

        {selectConstructionComplexes && (
          <RegistrationAreaConstructionComplexes
            setValue={setValue}
            control={control}
          />
        )}
      </CardContent>
    </Card>
  );
};
