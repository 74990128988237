import * as React from "react";
import { useTranslation } from "react-i18next";

import { setModal, useAppDispatch } from "app";
import { Icon } from "ui-kit";

import {
  ListItemIcon,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { IAgreementDuration, ModalVariants } from "@types";

const ITEM_HEIGHT = 48;

interface Props {
  amountOfDays: number;
  onUpdateDuration?: (duration: IAgreementDuration) => void;
  onDelete: ({ id, queueNumber }: { id: number; queueNumber: number }) => void;
  onMoveLeft: ({
    id,
    queueNumber,
  }: {
    id: number;
    queueNumber: number;
  }) => void;
  onMoveRight: ({
    id,
    queueNumber,
  }: {
    id: number;
    queueNumber: number;
  }) => void;
  queueNumber: number;
  id: number;
  allowedDelete: boolean;
  allowedLeft: boolean;
  allowedRight: boolean;
}

export const ApprovalSubjectActionsMenu = ({
  amountOfDays,
  queueNumber,
  id,
  onUpdateDuration,
  allowedDelete,
  allowedLeft,
  onMoveRight,
  allowedRight,
  onDelete,
  onMoveLeft,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation("correspondence");
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget as unknown as HTMLElement);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cb = (duration: number) => {
    if (onUpdateDuration) {
      onUpdateDuration({ duration });
    }
  };

  const handleChange = (type: string) => {
    setAnchorEl(null);

    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.changeApprovalRoute,
        content: {
          data: {
            type,
            duration: amountOfDays,
            queueNumber: queueNumber,
            changeDuration: cb,
          },
        },
      })
    );
  };

  const handleDelete = ({
    queueNumber,
    id,
  }: {
    queueNumber: number;
    id: number;
  }) => {
    setAnchorEl(null);
    onDelete({ id, queueNumber });
  };

  const handleMoveToLeft = ({
    queueNumber,
    id,
  }: {
    queueNumber: number;
    id: number;
  }) => {
    setAnchorEl(null);
    onMoveLeft({ id, queueNumber });
  };

  const handleMoveToRight = ({
    queueNumber,
    id,
  }: {
    queueNumber: number;
    id: number;
  }) => {
    setAnchorEl(null);
    onMoveRight({ id, queueNumber });
  };

  return (
    <Stack display="flex">
      <Icon
        onClick={handlePopoverOpen}
        name="MoreVerticalIcon"
        color={theme.palette.primary.main}
      />
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: "20ch",
            borderRadius: "16px",
          },
        }}
      >
        <MenuItem
          key="queue"
          sx={{ px: 4, py: 3 }}
          disabled={allowedRight}
          onClick={() => handleMoveToRight({ queueNumber, id })}
        >
          <ListItemIcon>
            <ListItemIcon>
              <Icon name="MoveRight" color={theme.palette.primary.main} />
            </ListItemIcon>
          </ListItemIcon>
          <Typography variant="body1">{t("move_right")}</Typography>
        </MenuItem>
        <MenuItem
          key="queue"
          sx={{ px: 4, py: 3 }}
          disabled={allowedLeft}
          onClick={() => handleMoveToLeft({ queueNumber, id })}
        >
          <ListItemIcon>
            <ListItemIcon>
              <Icon name="MoveLeft" color={theme.palette.primary.main} />
            </ListItemIcon>
          </ListItemIcon>
          <Typography variant="body1">{t("move_left")}</Typography>
        </MenuItem>
        <MenuItem
          key="duration"
          sx={{ px: 4, py: 3 }}
          onClick={() => handleChange("duration")}
        >
          <ListItemIcon>
            <Icon name="Calendar" color={theme.palette.primary.main} />
          </ListItemIcon>
          <Typography variant="body1">{t("change_duration")}</Typography>
        </MenuItem>
        <MenuItem
          key="delete"
          disabled={allowedDelete}
          onClick={() => handleDelete({ queueNumber, id })}
          sx={{ px: 4, py: 3 }}
        >
          <ListItemIcon>
            <Icon name="Trash2" color={theme.palette.error.main} />
          </ListItemIcon>
          <Typography variant="body1" color={"error.main"}>
            {t("delete")}
          </Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
};
