import React, { useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useGetEmployeesInHolding } from "api";
import { Modal } from "ui-kit";

import {
  Autocomplete,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import { Button, FormSelect, Input } from "@sbm/ui-components";
import { IAddAgreement } from "@types";

export const AddAgreementRouteApproverPopUp = ({
  open,
  onClose,
  data,
}: {
  open: boolean;
  onClose: () => void;
  data: {
    data: {
      duration: number;
      queueCount: number;
      onUpdate: any;
    };
  };
}) => {
  const { t } = useTranslation("correspondence");
  const { t: tCommon } = useTranslation();
  const theme = useTheme();

  const { duration, queueCount, onUpdate } = data.data;

  const { register, control, handleSubmit, setValue, getValues } =
    useForm<IAddAgreement>({
      defaultValues: {
        duration,
      },
    });

  const fields = useWatch({ control });
  const { duration: routeDuration, queueNumber } = fields;

  const { data: employeesList } = useGetEmployeesInHolding({
    limit: 249,
  });

  const queueOptionsList = useMemo(() => {
    const arr = [];
    if (queueCount) {
      for (let i = 1; i <= queueCount; ++i) {
        arr.push({
          option: `${i}`,
          value: i,
        });
      }
    }
    return arr;
  }, [queueCount]);

  const employeesOption =
    employeesList?.items?.map((employee) => {
      return {
        title: employee.personalData.longName,
        id: employee.employeeId,
        positionName: employee.structuralUnit?.positionName,
        nameOfStructuralUnit: employee.structuralUnit?.nameOfStructuralUnit,
        briefTextOrganizationName:
          employee.structuralUnit?.briefTextOrganizationName,
        externalSubstitutionId: employee.externalSubstitutionId,
        personalData: employee.personalData,
      };
    }) || [];

  const handleInputChange = (
    _: React.SyntheticEvent,
    values: {
      title: string | null;
      id: number;
      positionName?: string;
      briefTextOrganizationName: string | null;
      nameOfStructuralUnit: string;
      externalSubstitutionId: number | null;
    } | null
  ) => {
    if (values && values.id && values.title && values.nameOfStructuralUnit) {
      setValue("employeeId", values.id, { shouldDirty: true });
      setValue("externalSubstitutionId", values.externalSubstitutionId, {
        shouldDirty: true,
      });
      setValue("positionName", values?.positionName, { shouldDirty: true });
      setValue("nameOfStructuralUnit", values.nameOfStructuralUnit, {
        shouldDirty: true,
      });
      setValue("title", values.title, { shouldDirty: true });
      setValue("briefTextOrganizationName", values.briefTextOrganizationName, {
        shouldDirty: true,
      });
    } else {
      setValue("employeeId", null);
      setValue("externalSubstitutionId", null);
      setValue("positionName", undefined);
      setValue("title", "");
      setValue("nameOfStructuralUnit", "");
      setValue("briefTextOrganizationName", "");
    }
  };

  const onSubmit = handleSubmit(async () => {
    onUpdate({
      employeeId: getValues("employeeId"),
      positionName: getValues("positionName"),
      duration: getValues("duration"),
      title: getValues("title"),
      nameOfStructuralUnit: getValues("nameOfStructuralUnit"),
      queueNumber: getValues("queueNumber"),
    });
    onClose();
  });

  const dependencyTitle = getValues("title");

  const isDisabled = useMemo(
    () =>
      !routeDuration ||
      routeDuration < 1 ||
      !dependencyTitle?.length ||
      !queueNumber,
    [routeDuration, queueNumber, dependencyTitle]
  );

  const content = {
    modalTitle: t("add_approver"),
    body: (
      <Stack mb={6}>
        <Grid container spacing={2} rowGap={2}>
          <Grid item xs={12}>
            <Autocomplete
              options={employeesOption}
              onChange={handleInputChange}
              getOptionLabel={(option) => option?.title || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  label={t("employee_name")}
                  required
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Stack flexDirection="column" justifyContent="center" gap={1}>
                    <Typography
                      color="text.primary"
                      variant="subtitle1"
                      sx={{ lineHeight: "16px" }}
                    >
                      {option.title}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color={hexToRgba(theme.palette.text.primary, 0.7)}
                      sx={{ lineHeight: "16px" }}
                    >
                      {option.nameOfStructuralUnit}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      color={hexToRgba(theme.palette.text.primary, 0.7)}
                      sx={{ lineHeight: "16px" }}
                    >
                      {option.briefTextOrganizationName}
                    </Typography>
                  </Stack>
                </li>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              label={t("approval_queue")}
              name="queueNumber"
              control={control}
              values={queueOptionsList}
            />
          </Grid>

          <Grid item xs={6}>
            <Input
              variant="outlined"
              size="medium"
              label={t("duration")}
              type="text"
              {...register("duration")}
              onBeforeInput={(event) => {
                const inputEvent = event.nativeEvent as InputEvent;
                if (!/^\d$/.test(inputEvent.data || "")) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => {
                const sanitizedValue = event.target.value.replace(
                  /^0+(?=\d)/,
                  ""
                );
                const numericValue = sanitizedValue
                  ? parseInt(sanitizedValue, 10)
                  : 0;
                setValue("duration", numericValue, { shouldDirty: true });
              }}
            />

            {routeDuration && routeDuration > 0 ? null : (
              <Typography variant="body3" fontWeight={500} color="error.main">
                {tCommon("duration_warning_message")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Stack>
    ),

    mainButton: (
      <Stack flexDirection="row" justifyContent="end" width="100%" gap={2}>
        <Button
          autoFocus
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          {t("cancel")}
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="secondary"
          disabled={isDisabled}
          onClick={onSubmit}
        >
          {t("add")}
        </Button>
      </Stack>
    ),
  };

  return (
    <Modal isOpen={open} onClose={onClose} content={content} updatedVersion />
  );
};
