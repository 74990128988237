import styled from "styled-components";
import { FLEX_COLUMN_FULL, FLEX_ROW } from "ui-kit";

export const HomePageCardWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing(7, 4, 0, 10)};

  width: 100%;
  min-height: 230px;

  border-radius: 16px;
  background: linear-gradient(
    95deg,
    ${({ theme }) => theme.palette.primary.main} 100%,
    ${({ theme }) => theme.palette.primary.dark} 100%
  );

  box-shadow: ${({ theme }) => theme.shadows[4]};
`;
export const HomeCardImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const CardWidgetIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.palette.secondary.light};
  ${FLEX_COLUMN_FULL};
  border-radius: ${({ theme }) => theme.spacing(2)};
`;

export const CardWidgetCountWrapper = styled.div`
  height: 40px;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  background: ${({ theme }) => theme.palette.primary.main};
  ${FLEX_COLUMN_FULL};
  border-radius: ${({ theme }) => theme.spacing(2)};
`;
