import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "app";
import {
  axiosService,
  useAppSelector,
  getUserEmployeeOrganizationsAndPositions,
  getUserExternalSubstitutionOrganizationsAndPositions,
} from "app";

import { Card, CardContent, Stack, Typography, useTheme } from "@mui/material";
import { FormAutocomplete, FormRadio, FormSelect } from "@sbm/ui-components";
import { DeliveryMethodEnum, StorageOrderEnum } from "@types";

import { DetailsStepProps } from "../DetailsStep";

export const DetailsStepAdditionalInfo: React.FC<
  Pick<DetailsStepProps, "control" | "setValue"> & { isEditMode: boolean }
> = ({ control, setValue, isEditMode = false }) => {
  const { palette } = useTheme();
  const { t } = useTranslation("correspondence");

  const { userProfile } = useAppSelector((state) => state.auth);
  const {
    deliveryMethod,
    legalEntity,
    emailId,
    recipientOrganizationId,
    senderOrganizationId,
    personalDataId,
  } = useWatch({ control });

  const [inputEmailValue, setInputEmailValue] = React.useState("");

  const [emailOptions, setEmailOptions] = React.useState<
    { title: string; id?: number }[]
  >([]);

  const userEmployeeOrganizationsAndPositions =
    getUserEmployeeOrganizationsAndPositions(userProfile?.personalData);

  const userEmployeeOrganizationsAndPositionsIds =
    userEmployeeOrganizationsAndPositions.map((i) => i.id);

  const userExternalSubstitutionOrganizationsAndPositions =
    getUserExternalSubstitutionOrganizationsAndPositions(
      userProfile?.personalData
    );

  const userExternalSubstitutionOrganizationsAndPositionsIds =
    userExternalSubstitutionOrganizationsAndPositions.map((i) => i.id);

  const isLegalEntity =
    typeof legalEntity === "string" ? legalEntity === "true" : legalEntity;

  const isEmailDelivery = deliveryMethod === DeliveryMethodEnum.byEmail;

  const isEmployeeAndExternalSubInSameOrganization =
    recipientOrganizationId &&
    userEmployeeOrganizationsAndPositionsIds.includes(
      recipientOrganizationId
    ) &&
    userExternalSubstitutionOrganizationsAndPositionsIds.includes(
      recipientOrganizationId
    );

  const employeeAndExternalSubPositionOptions = React.useMemo(() => {
    if (!recipientOrganizationId || !isEmployeeAndExternalSubInSameOrganization)
      return [];

    const employeePosition = userEmployeeOrganizationsAndPositions.find(
      (i) => i.id === recipientOrganizationId
    )?.position;

    const externalPosition =
      userExternalSubstitutionOrganizationsAndPositions.find(
        (i) => i.id === recipientOrganizationId
      )?.position;

    if (!externalPosition || !employeePosition) return [];

    // find repeated org id and show position for employee
    const employeeOption = {
      option: employeePosition,
      value: "employee",
    };
    // find repeated org id and show position for external sub
    const externalSubOption = {
      option: externalPosition,
      value: "external",
    };

    return [employeeOption, externalSubOption];
  }, [
    isEmployeeAndExternalSubInSameOrganization,
    recipientOrganizationId,
    userEmployeeOrganizationsAndPositions,
    userExternalSubstitutionOrganizationsAndPositions,
  ]);

  const storageOrderOptions = React.useMemo(() => {
    return Object.values(StorageOrderEnum).map((item) => ({
      option: t(`storageForOrder.${item}`),
      value: item,
    }));
  }, [t]);

  const deliveryOptions = React.useMemo(() => {
    return Object.values(DeliveryMethodEnum).map((item) => ({
      option: t(`deliveryMethod.${item}`),
      value: item,
    }));
  }, [t]);

  const getEmailDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !emailOptions.length) return "";

      const option = emailOptions.find((i) => i.id === id);
      if (!option) return "";
      return option.title;
    },
    [emailOptions]
  );

  const fetchEmails = React.useCallback(
    async (search: string) => {
      const body: {
        page: number;
        limit: number;
        search: string;
        "filter.organizationId"?: number;
        "filter.personalDataId"?: number;
      } = {
        page: 1,
        limit: 50,
        search,
      };

      if (senderOrganizationId && isLegalEntity) {
        body["filter.organizationId"] = senderOrganizationId;
        delete body["filter.personalDataId"];
      }

      if (personalDataId && !isLegalEntity) {
        body["filter.personalDataId"] = personalDataId;
        delete body["filter.organizationId"];
      }

      const { data } = await axiosService({
        endpoint: isLegalEntity ? "/email-of-organization" : "/person-email",
        body,
      });

      const options = data
        ? (data.items as { email: { email: string; id: number } }[])
            .map((i) => ({
              title: i.email?.email,
              id: i.email?.id,
            }))
            .filter((i) => Boolean(i.title))
        : [];

      return options || [];
    },
    [isLegalEntity, personalDataId, senderOrganizationId]
  );

  const handleEmailInputChange = (val: string, reason: string) => {
    setInputEmailValue(val);

    if (reason === "input") return;
    // Delete action
    if (reason === "clear") {
      setValue("emailId", undefined);
      return;
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = emailOptions.find((i) => i.title === val);
      if (!option) return;
      setValue("emailId", option.id);
    }
  };

  useEffect(() => {
    if (isEmailDelivery) {
      setValue("storageOrderForOriginal", undefined);
    }
  }, [isEmailDelivery, setValue]);

  useEffect(() => {
    setInputEmailValue("");
    setValue("emailId", undefined, { shouldDirty: true });
  }, [isLegalEntity, setValue]);

  useEffect(() => {
    setValue("creatorPosition", undefined);
  }, [recipientOrganizationId, setValue]);

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h9_semiBold" color="text.disabled" sx={{ mb: 2 }}>
          {t("createLetterDrawer.additional_info.title").toUpperCase()}
        </Typography>

        <FormSelect
          required
          name="deliveryMethod"
          control={control}
          label={t("createLetterDrawer.additional_info.delivery_method_label")}
          values={deliveryOptions}
        />

        {!isEmailDelivery && (
          <FormSelect
            required
            name="storageOrderForOriginal"
            control={control}
            label={t(
              "createLetterDrawer.additional_info.storage_for_order_label"
            )}
            values={storageOrderOptions}
          />
        )}

        {isEmailDelivery && (
          <FormAutocomplete
            freeSolo={false}
            inputValue={inputEmailValue}
            label={t("createLetterDrawer.additional_info.email_label")}
            defaultSelected={getEmailDefaultValue(emailId)}
            fetchData={fetchEmails}
            onFetchDataSuccess={setEmailOptions}
            onInputChange={handleEmailInputChange}
          />
        )}

        {isEmployeeAndExternalSubInSameOrganization &&
          employeeAndExternalSubPositionOptions.length > 0 &&
          !isEditMode && (
            <Stack>
              <FormRadio
                required
                labelPropsSx={{
                  fontWeight: 700,
                  color: palette.primary.main,
                  mb: 2,
                }}
                label={t(
                  "createLetterDrawer.additional_info.position_checkbox_label"
                )}
                name="creatorPosition"
                control={control}
                values={employeeAndExternalSubPositionOptions}
              />
            </Stack>
          )}
      </CardContent>
    </Card>
  );
};
