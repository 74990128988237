import React from "react";
import { useTranslation } from "react-i18next";

import {
  axiosService,
  openDrawerWithData,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Icon } from "ui-kit";

import {
  ListItemIcon,
  useTheme,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { downloadFile } from "@sbm/fe-utils";
import { DrawerWithDataVariantsEnum } from "@types";

interface Props {
  fileNameForSystem: string;
  fileName: string;
  uploaderId: number;
  fileId: number;
  additionalInformation: string;
  typeOfAttachment: string;
  fileFormat: string;
  fileStatus: string;
}
export const CorrespondenceAttachedFileActions: React.FC<Props> = ({
  fileNameForSystem,
  fileName,
  uploaderId,
  additionalInformation,
  typeOfAttachment,
  fileFormat,
  fileId,
  fileStatus,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("correspondence");
  const { userProfile } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = async () => {
    const { data: linkToFile } = await axiosService({
      endpoint: `contract-attached-files/get-link`,
      body: { filename: fileNameForSystem },
    });

    downloadFile(linkToFile, fileName);

    setTimeout(() => {
      setAnchorEl(null);
    }, 200);
  };

  const handleEdit = () => {
    dispatch(
      openDrawerWithData({
        open: true,
        variant: DrawerWithDataVariantsEnum.editCorrespondenceAttachment,
        content: {
          data: {
            additionalInformation: additionalInformation,
            typeOfAttachment: typeOfAttachment,
            fileNameForSystem: fileNameForSystem,
            fileFormat: fileFormat,
            fileId: fileId,
            fileStatus: fileStatus,
          },
        },
      })
    );
    setAnchorEl(null);
  };

  return (
    <div>
      <Icon
        onClick={handlePopoverOpen}
        name="MoreVerticalIcon"
        color={theme.palette.primary.main}
      />

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "20ch",
            borderRadius: "16px",
          },
        }}
      >
        <MenuItem onClick={handleClick} key="Download" sx={{ px: 4, py: 3 }}>
          <ListItemIcon>
            <Icon name="Download" color={theme.palette.primary.main} />
          </ListItemIcon>
          <Typography variant="body1">
            {t("attachmentFilesAndLinks.download_file")}
          </Typography>
        </MenuItem>
        <MenuItem
          disabled={uploaderId !== userProfile?.id}
          key="edit"
          sx={{ px: 4, py: 3 }}
          onClick={handleEdit}
        >
          <ListItemIcon>
            <Icon name="PencilIcon" color={theme.palette.primary.main} />
          </ListItemIcon>
          <Typography variant="body1">{t("edit")}</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};
