import { axiosService } from "app";

import { getFilterParams } from "@sbm/fe-utils";
import { QueryFunctionContext } from "@tanstack/react-query";
import {
  CorrespondenceTypeEnum,
  FileAttachedToCorrespondenceEditTypeEnum,
  IAttachesTaskLinkBody,
  IncomingDocumentEntity,
  OutgoingDocumentEntity,
  ResolutionRecipientEmployeeEnums,
  ServiceNoteEntity,
  TypeResourceEnum,
} from "@types";

export const getCorrespondenceIncoming = async (
  { pageParam }: QueryFunctionContext,
  filter: (string | undefined)[] = [],
  sortBy = "",
  search = ""
) => {
  const page = pageParam ?? 1;
  const limit = 20;

  const dateFilters = [
    "filter.registrationDate",
    "filter.dateOfRegistrationCounterparty",
    "filter.createdAt",
    "filter.correspondenceType",
  ];
  const filterParams = getFilterParams(filter, dateFilters);

  const params = {
    search,
    sortBy,
    limit,
    page,
    ...filterParams,
  };

  const { data } = await axiosService({
    endpoint: "/correspondence",
    body: params,
  });

  return data;
};

export const fetchCorrespondence = async (
  search = "",
  type: CorrespondenceTypeEnum = CorrespondenceTypeEnum.incomingDocument
) => {
  const params: {
    limit: number;
    page: number;
    search: string;
    "filter.correspondenceType": CorrespondenceTypeEnum;
  } = {
    limit: 200,
    page: 1,
    search,
    "filter.correspondenceType": type,
  };

  const { data } = await axiosService({
    endpoint: `correspondence`,
    body: params,
  });

  return data;
};

export const fetchDocumentById = async (id: string | undefined) => {
  if (!id) return null;

  const { data } = await axiosService({
    endpoint: `/correspondence/${id}`,
  });

  return data;
};

export const fetchCorrespondenceEvents = async (id: string | undefined) => {
  if (!id) return null;

  const { data } = await axiosService({
    endpoint: `/event-for-document/correspondence/${id}`,
  });

  return data;
};

export const fetchCorrespondenceFilesById = async (
  correspondenceId: number | undefined
) => {
  if (!correspondenceId) return null;

  const { data } = await axiosService({
    endpoint: `/correspondence/${correspondenceId}/files`,
  });

  return data;
};

export const fetchCorrespondenceLinksById = async (
  correspondenceId: number | undefined
) => {
  if (!correspondenceId) return null;

  const { data } = await axiosService({
    endpoint: `/correspondence/${correspondenceId}/links`,
  });

  return data;
};

export const fetchDefaultApprovalRoute = async (
  correspondenceId: number | undefined
) => {
  if (!correspondenceId) return null;

  const { data } = await axiosService({
    endpoint: `/correspondence/${correspondenceId}/default-approval-route`,
  });

  return data;
};

export const uploadCorrespondenceFile = async (body: {
  requestBody: FormData;
  correspondenceId: number;
}) => {
  const { data } = await axiosService({
    endpoint: `/correspondence/${body.correspondenceId}/files`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    body: body.requestBody,
  });

  return data;
};

export const uploadCorrespondenceLinks = async (body: {
  requestBody: IAttachesTaskLinkBody;
  correspondenceId: number;
}) => {
  const { data } = await axiosService({
    endpoint: `/correspondence/${body.correspondenceId}/links`,
    method: "PUT",
    body: body.requestBody,
  });

  return data;
};

export const sendToResolution = async (body: {
  correspondenceId: number;
  recipientEmployeeId: number | null;
  recipientEmployeeType: ResolutionRecipientEmployeeEnums;
  isRecipientNotified?: boolean;
}) => {
  const { data } = await axiosService({
    endpoint: `/correspondence/${body.correspondenceId}/incoming/send-to-resolution`,
    method: "POST",
    body:
      body.recipientEmployeeType !== ResolutionRecipientEmployeeEnums.CURRENT
        ? {
            recipientEmployeeId: body.recipientEmployeeId,
            isRecipientNotified: body?.isRecipientNotified,
          }
        : {},
  });

  return data;
};

export const createIncomingDocument = async (body: FormData) => {
  const { data } = await axiosService({
    endpoint: `/correspondence`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    body,
  });

  return data;
};

export const createOutgoingDocument = async (body: FormData) => {
  const { data } = await axiosService({
    endpoint: `/correspondence/outgoing`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    body,
  });

  return data;
};

export const getFiltersData = async (
  correspondenceTypeEnum: CorrespondenceTypeEnum
) => {
  const params = {
    "filter.correspondenceType": correspondenceTypeEnum,
  };

  const { data } = await axiosService({
    endpoint: "/correspondence/filters",
    body: params,
  });

  return data;
};

export const getTopics = async ({
  organizationId,
  typeResourceId,
  search,
}: {
  organizationId?: number;
  typeResourceId: TypeResourceEnum;
  search: string;
}) => {
  if (!organizationId) return null;

  const params = {
    "filter.organizationId": organizationId,
    "filter.typeResourceId": typeResourceId,
    search,
  };
  const { data } = await axiosService({
    endpoint: "/text-for-topics",
    body: params,
  });

  return data;
};

export const createServiceNote = async (body: FormData) => {
  const { data } = await axiosService({
    endpoint: `/correspondence/service-note`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    body,
  });

  return data;
};

export const editCorrespondence = async (
  payload: ServiceNoteEntity | OutgoingDocumentEntity | IncomingDocumentEntity
) => {
  const id = payload.id;

  delete payload["id"];

  const { data } = await axiosService({
    endpoint: `/correspondence/${id}`,
    method: "PUT",
    body: payload,
  });

  return data;
};

export const deleteCorrespondence = async (id: number) => {
  const { data } = await axiosService({
    endpoint: `/correspondence/${id}`,
    method: "DELETE",
  });

  return data;
};

export const editDeleteCorrespondenceAttachedFile = async (
  body: FormData,
  correspondenceId: number,
  type: FileAttachedToCorrespondenceEditTypeEnum,
  fileId?: number
) => {
  const { data } = await axiosService({
    endpoint: `/correspondence/${correspondenceId}/files`,
    method:
      type === FileAttachedToCorrespondenceEditTypeEnum.delete
        ? "DELETE"
        : "PUT",
    body:
      type === FileAttachedToCorrespondenceEditTypeEnum.delete
        ? { fileAttachedToCorrespondenceId: fileId }
        : body,
  });

  return data;
};
