import React from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import {
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { DetailsStepProps } from "../DetailsStep";
import { OutgoingDocumentActorForm } from "./OutgoingDocumentActorForm";

export const DetailsStepActor: React.FC<
  Pick<
    DetailsStepProps,
    "control" | "setValue" | "register" | "errors" | "isEditMode"
  >
> = ({ control, setValue, isEditMode }) => {
  const { palette } = useTheme();
  const { t } = useTranslation("correspondence");

  const { recipients = [] } = useWatch({ control });

  const handleRemoveRecipient = (position: number) => {
    const newRecipients = recipients.filter((_, i) => i !== position);
    setValue("recipients", newRecipients, { shouldDirty: true });

    // Manually set the right positions, because there was rerender issue
    newRecipients.forEach((rec, index) => {
      setValue(
        // @ts-ignore
        `recipients[${index}].recipientEmployeePosition`,
        rec.recipientEmployeePosition
      );
    });
  };

  const handleAddRecipient = () => {
    const newRecipients =
      recipients.length > 0
        ? [...recipients, { legalEntity: true }]
        : [{ legalEntity: true }];
    setValue("recipients", newRecipients, { shouldDirty: true });
  };

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h9_semiBold" color="text.disabled">
            {t("createLetterDrawer.recipient_area.title").toUpperCase()}
          </Typography>

          <Button size="large" color="secondary" onClick={handleAddRecipient}>
            {t("createOutgoingDocument.recipient_area.add_button")}
          </Button>
        </Stack>

        {recipients.map((_, index) => {
          return (
            <Stack key={index + 1}>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                height={36}
              >
                <Typography variant="h9_semiBold" color="text.secondary">
                  {t("createOutgoingDocument.recipient_area.recipient", {
                    position: index + 1,
                  })}
                </Typography>

                {recipients.length > 1 && (
                  <Button
                    variant="text"
                    size="medium"
                    color="error"
                    onClick={() => handleRemoveRecipient(index)}
                    startIcon={
                      <Icon name="Trash2" color={palette.error.main} />
                    }
                  >
                    {t("createOutgoingDocument.recipient_area.remove_button")}
                  </Button>
                )}
              </Stack>

              <OutgoingDocumentActorForm
                setValue={setValue}
                control={control}
                position={index}
                isEditMode={isEditMode}
              />
            </Stack>
          );
        })}
      </CardContent>
    </Card>
  );
};
