import React from "react";

import { TFunction } from "i18next";

import { Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { COLUMN_SIZE, getLocaleDateFormat } from "@sbm/fe-utils";

export const getAttachLinkTableColumns = (t: TFunction): GridColDef[] => {
  return [
    {
      field: "sequenceNumber",
      headerName: t("sequence.number"),
      sortable: false,
      filterable: false,
      width: 60,
    },
    {
      field: "documentNumber",
      headerName: t("document.number"),
      sortable: false,
      filterable: false,
      width: 160,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography variant="body2">{params.row.documentNumber}</Typography>
            <Typography variant="body2" color="text.secondary">
              {getLocaleDateFormat(params.row.documentDate)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "longNameOfTheDocument",
      headerName: t("long_name"),
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Tooltip arrow placement="top" title={params.row.documentName}>
              <Typography variant="body2">{params.row.documentName}</Typography>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "context",
      headerName: t("context"),
      sortable: false,
      filterable: false,
      minWidth: COLUMN_SIZE * 4,
      renderCell: (params) => {
        return (
          <Stack>
            <Tooltip
              arrow
              placement="top"
              title={params.row.contextOfTheDocument}
            >
              <Typography variant="body2">
                {params.row.contextOfTheDocument}
              </Typography>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "id",
      headerName: t("internal.number"),
      sortable: true,
      filterable: false,
      minWidth: COLUMN_SIZE * 2,
    },
    {
      field: "createdAt",
      headerName: t("date.of.creation"),
      sortable: true,
      filterable: false,
      width: 140,
    },
  ];
};

export const ATTACHED_LINKS_FILTERABLE_FIELDS = [
  {
    title: "date.of.creation",
    field: "createdAt",
    options: [],
    optionType: "range",
    value: "",
  },
];
