import { createGlobalStyle } from "styled-components";

import {
  cardClasses,
  formControlLabelClasses,
  formLabelClasses,
  inputClasses,
  selectClasses,
  Theme,
} from "@mui/material";

import winter_bg from "../../assets/backgrounds/winter_bg.svg";

export const MyGlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  // Add font family
  @font-face {
    font-weight: normal;
    font-style: normal;
  }

  html, body, #root {
    height: 100%;
    font-size: 14px;
  }

  * {
    margin: 0;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    background-color: ${({ theme }) => {
      const muiTheme = theme as Theme;
      return muiTheme.palette.primary.main;
    }};
    background-image: url(${winter_bg});
  }

  span {
    user-select: none;
  }

  img, picture, video, canvas {
    display: block;
    max-width: 100%;
  }

  button {
    border: none;
    outline: none;
  }

  input, button, textarea, select {
    font: inherit;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  #root {
    isolation: isolate;
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .no-underline {
    text-decoration: none;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .${formLabelClasses.root}, .${formControlLabelClasses.label}, .${
  inputClasses.root
}, .${selectClasses.select} {
    font-size: 14px;
  }

  .${cardClasses.root} {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.0) 0rem 0.125rem 0.25rem -0.0625rem;
  }


  :not(.MuiDataGrid-virtualScroller) {
    /* Hide scrollbar for Chrome, Safari and Opera */

    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .MuiDataGrid-virtualScroller {
    /* Scroll bar  */

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /* Handle */

    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: ${({ theme }) => {
        const muiTheme = theme as Theme;
        return muiTheme.palette.text.disabled;
      }};

      &:hover {
        background: ${({ theme }) => {
          const muiTheme = theme as Theme;
          return muiTheme.palette.text.secondary;
        }}
      }
    }
  }

  .rbc-event {
    padding: 0;
  }

  .rbc-event,
  .rbc-event-allday {
    &:hover {
      box-shadow: ${({ theme }) => {
        const muiTheme = theme as Theme;
        return muiTheme.shadows[4];
      }}
    }
  }

  .input-number__disabled-arrows {

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  }

`;
