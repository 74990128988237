import React from "react";
import { useTranslation } from "react-i18next";

import { useViewMessageMutation } from "api";
import { openDrawer, useAppDispatch, useAppSelector } from "app";

import { Avatar, Stack, Typography, Divider } from "@mui/material";
import { DrawerVariantsEnum, INotification } from "@types";

import female_avatar from "../../../../../../assets/images/avatars/winter_female_avatar.png";
import male_avatar from "../../../../../../assets/images/avatars/winter_male_avatar.png";
import { Wrapper } from "./styles";

interface InboxNotificationsProps {
  notifications: INotification[];
  onClose: () => void;
  onMessageView: (id: number) => void;
  setCurrentMessageId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const InboxNotifications: React.FC<InboxNotificationsProps> = ({
  notifications,
  onMessageView,
  setCurrentMessageId,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { userProfile } = useAppSelector((state) => state.auth);

  const { mutate: viewMessage } = useViewMessageMutation();

  const handleViewNotification = async (id: number | undefined) => {
    if (!id) return;

    setCurrentMessageId(id);
    dispatch(openDrawer(DrawerVariantsEnum.viewNotification));

    setTimeout(() => {
      viewMessage({ id, view: true });
      onMessageView(id);
    }, 200);
  };

  if (!notifications?.length) return null;

  const avatarSrc =
    userProfile?.personalData?.gender === "female"
      ? female_avatar
      : male_avatar;

  return (
    <Wrapper>
      {notifications.map((item, index) => (
        <React.Fragment key={`${item.id}-${index}`}>
          <Stack
            flexDirection="row"
            gap={2}
            className="cursor-pointer"
            onClick={() => handleViewNotification(item.messageNumber)}
          >
            <Stack>
              <Avatar variant="circular" src={avatarSrc} />
            </Stack>

            <Stack>
              <Typography variant="body2" fontWeight={700}>
                {item.messageAuthor?.typeOfAuthor === "system"
                  ? t("sbm.assistant")
                  : item.messageAuthor?.authorName}
              </Typography>
              <Typography variant="body3">{item.messageText}</Typography>
            </Stack>
          </Stack>

          <Divider sx={{ bgcolor: "primary.dark" }} />
        </React.Fragment>
      ))}
    </Wrapper>
  );
};
