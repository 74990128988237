import * as React from "react";
import { useTranslation } from "react-i18next";

import { openDrawer, useAppDispatch } from "app";
import { Icon } from "ui-kit";

import {
  ListItemIcon,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { DrawerVariantsEnum } from "@types";

const ITEM_HEIGHT = 48;

export const IncomingDocumentActionsMenu = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("correspondence");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget as unknown as HTMLElement);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    dispatch(openDrawer(DrawerVariantsEnum.editCorrespondence));
    handleClose();
  };

  return (
    <Stack display="flex">
      <Icon
        onClick={handlePopoverOpen}
        name="MoreVerticalIcon"
        color={theme.palette.secondary.main}
      />
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: "20ch",
            borderRadius: "16px",
          },
        }}
      >
        <MenuItem key="edit" sx={{ px: 4, py: 3 }} onClick={handleEdit}>
          <ListItemIcon>
            <Icon name="PencilIcon" color={theme.palette.primary.main} />
          </ListItemIcon>
          <Typography variant="body1">{t("edit")}</Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
};
