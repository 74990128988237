import React from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

import { Paths, useAppSelector } from "app";
import { Icon } from "ui-kit";

import { Avatar, Popover, Stack } from "@mui/material";

import female_avatar from "../../../../../../../assets/images/avatars/winter_female_avatar.png";
import male_avatar from "../../../../../../../assets/images/avatars/winter_male_avatar.png";
import { AvatarMenu } from "./components";
import { AvatarWrapper } from "./styles";

export const UserAvatar = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [iconName, setIconName] = React.useState<"Maximize2" | "Minimize2">(
    "Maximize2"
  );
  const { userProfile } = useAppSelector((state) => state.auth);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    handleProfileMenuOpen(event);
  };

  const handleMenuClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const navigateToProfile = React.useCallback(() => {
    handleMenuClose();
    navigate(`${Paths.MY_PROFILE}`);
  }, [handleMenuClose, navigate]);

  const handleLogout = React.useCallback(() => {
    handleMenuClose();
    void auth.signoutRedirect();
  }, [auth, handleMenuClose]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setIconName("Minimize2");
      });
    } else if (document.exitFullscreen) {
      document.exitFullscreen().then(() => {
        setIconName("Maximize2");
      });
    }
  };

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Icon name={iconName} onClick={toggleFullScreen} />

      <AvatarWrapper>
        <Avatar
          className="avatar"
          src={
            userProfile?.personalData?.gender === "female"
              ? female_avatar
              : male_avatar
          }
          variant="rounded"
          onClick={handleAvatarClick}
        />

        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            sx: {
              borderRadius: "16px",
            },
          }}
        >
          <AvatarMenu
            handleLogout={handleLogout}
            navigateToProfile={navigateToProfile}
          />
        </Popover>
      </AvatarWrapper>
    </Stack>
  );
};
