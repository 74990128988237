import React from "react";

import { Box, styled } from "@mui/material";

const Dot = styled(Box)(({ theme }) => ({
  width: "4px", // Slightly larger for better visibility
  height: "4px",
  margin: "0 3px", // Adjusted spacing for balance
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  animation: "smoothBounce 1.2s infinite cubic-bezier(0.25, 0.1, 0.25, 1)",
  opacity: 0.6, // Initial opacity for subtle effect
  "&:nth-of-type(2)": {
    animationDelay: "0.2s",
  },
  "&:nth-of-type(3)": {
    animationDelay: "0.4s",
  },
}));

const TypingIndicator = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@keyframes smoothBounce": {
    "0%, 80%, 100%": {
      transform: "scale(0.8)",
      opacity: 0.4,
    },
    "40%": {
      transform: "scale(1.2)",
      opacity: 1,
    },
  },
}));

export const TypingAnimation = () => {
  return (
    <TypingIndicator>
      <Dot />
      <Dot />
      <Dot />
    </TypingIndicator>
  );
};
