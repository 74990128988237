export enum FileStatusEnum {
  signed = "signed",
  draft = "draft",
}

export enum FileAppointmentEnum {
  contract = "contract",
  appendix_to_contract = "appendix_to_contract",
  disagreement_protocol = "disagreement_protocol",
  supporting_file = "supporting_file",
  supplementary_agreement = "supplementary_agreement",
  appendix_to_supplementary_agreement = "appendix_to_supplementary_agreement",
}

export enum ContractFileAppointmentEnum {
  contract = "contract",
  appendix_to_contract = "appendix_to_contract",
  disagreement_protocol = "disagreement_protocol",
  supporting_file = "supporting_file",
}

export enum SupplementaryAgreementFileAppointmentEnum {
  supporting_file = "supporting_file",
  supplementary_agreement = "supplementary_agreement",
  appendix_to_supplementary_agreement = "appendix_to_supplementary_agreement",
}

export const LinksTypeEnum = {
  internalLink: "internalLink",
  externalLink: "externalLink",
};

export interface IAttachLinks {
  linkType: string;
  documentType?: string | undefined;
  externalLinkUrl: string;
}
